<template>
<v-dialog v-model="props.modal" max-width="60%" persistent>
  <v-card>
    <v-card-title>
      <v-card class="primary white--text titulomodal">
        <v-icon class="white--text">info</v-icon>&nbsp;
        <span class="subheading">
          <strong>HISTORICO DEL PRODUCTO</strong>
        </span>
      </v-card>
      <v-spacer></v-spacer>
      <v-btn icon @click.native="cerrar" class="close_modal">
        <v-icon class="white--text">cancel</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>

      <br>
      <v-container>
        <v-row>
          <v-tabs grow style="margin-bottom: 30px;" v-model="model.tipo">
            <v-tab :key="0">Compras</v-tab>
            <v-tab :key="1" @click.native="model.plazo = props.plazo;">Ventas</v-tab>

            <v-tab-item :key="0">

              <v-form ref="form" lazy-validation>
                <div v-if="model.tipo == 0" class="form_compras">
                  <v-container grid-list-md @keyup.enter="consultar_compras()">
                    <v-row>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de" label="Fecha Inicio" color="secondary" prepend-icon="event" @blur="model.fecha_de = parse_date(model.fecha_de)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_de" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu1 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a" label="Fecha Fin" color="secondary" prepend-icon="event" @blur="model.fecha_a = parse_date(model.fecha_a)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_a" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu2 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-col sm="12" md="6" lg="6" style="text-align:right">
                        <v-btn small color="success" @click.native="consultar_compras()">
                          <v-icon>done</v-icon> Consultar
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>
              </v-form>

              <v-client-table ref="vuetable_compras" :data="items_compras" :columns="table.columns" :options="table.options">
                <template slot="total" slot-scope="props">
                  <div style="text-align:center">
                    <v-btn x-small fab dark color="blue" @click.native="calcular_fecha(props.row.fecha)" title="Calcular" v-tippy>
                      <v-icon>refresh</v-icon>
                    </v-btn>
                  </div>
                </template>
                <template slot="precio" slot-scope="props">
                  <div style="text-align:right">${{props.row.precio.toFixed(2)}}</div>
                </template>
                <template slot="cantidad" slot-scope="props">
                  <div style="text-align:center">{{props.row.cantidad}}</div>
                </template>
              </v-client-table>
              <!-- <v-card-text> -->
              <v-row>
                <v-col sm="12" md="6" lg="6">
                  <!-- <strong>Vendidos desde la ultima compra:</strong> {{ultimos_vendidos}} -->
                </v-col>
                <v-col sm="12" md="6" lg="6">
                  <strong>Total: </strong>{{total_compra}}<br>
                  <strong>Promedio: </strong>{{promedio_compra}}<br>
                  <strong>Precio Promedio: </strong>${{precio_promedio_compra.toFixed(2)}}
                </v-col>
              </v-row>
              <!-- </v-card-text> -->
            </v-tab-item>
            <v-tab-item :key="1">
              <v-form ref="form" lazy-validation>
                <div v-if="model.tipo == 1" class="form_ventas">
                  <v-container grid-list-md @keyup.enter="calcular()">
                    <v-row>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_de_venta" label="Fecha Inicio" color="secondary" prepend-icon="event" @blur="model.fecha_de_venta = parse_date(model.fecha_de_venta)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_de_venta" color="secondary" scrollable locale="es-mx" @input="menu3 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu3 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col sm="12" md="6" lg="6">
                        <v-menu offset-x ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                          <template v-slot:activator="{ on }">
                            <v-text-field v-on="on" v-model="model.fecha_a_venta" label="Fecha Fin" color="secondary" prepend-icon="event" @blur="model.fecha_a_venta = parse_date(model.fecha_a_venta)" :rules="[rules.required, valida_fecha]">
                            </v-text-field>
                          </template>

                          <v-date-picker v-model="model.fecha_a_venta" color="secondary" scrollable locale="es-mx" @input="menu4 = false">
                            <v-spacer />

                            <v-btn color="secondary" x-small @click="menu4 = false">
                              Cancelar
                            </v-btn>
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col sm="12" md="6" lg="6">
                        <v-radio-group v-model="model.periodo" row :rules="[rules.required]">
                          <template v-slot:label>
                            <div>Periodo</div>
                          </template>
                          <v-radio label="Día" value="1"></v-radio>
                          <v-radio label="Mes" value="2"></v-radio>
                          <v-radio label="Año" value="3"></v-radio>
                        </v-radio-group>
                      </v-col>
                      <v-col sm="12" md="3" lg="3">
                        <v-text-field label="Plazo" v-model="model.plazo" :rules="[rules.required]"></v-text-field>
                      </v-col>
                      <v-col sm="12" md="3" lg="3" style="text-align:right">
                        <v-btn small color="success" @click.native="calcular()">
                          <v-icon>done</v-icon> Calcular
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-container>
                </div>

              </v-form>
              <v-client-table ref="vuetable_ventas" :data="items" :columns="table.columns" :options="table.options">
                <template slot="total" slot-scope="props">
                </template>
                <template slot="cantidad" slot-scope="props">
                  <div style="text-align:center">{{props.row.cantidad}}</div>
                </template>
                <template slot="precio" slot-scope="props">
                  <div style="text-align:right">${{props.row.precio.toFixed(2)}}</div>
                </template>
              </v-client-table>
              <v-row>
                <v-col sm="12" md="6" lg="6">
                  <strong>Total: </strong>{{total_venta}}<br>
                  <strong>Cantidad Promedio: </strong>{{promedio_venta}}<br>
                  <strong>Precio Promedio: </strong>${{precio_promedio_venta.toFixed(2)}}
                </v-col>
                <v-col sm="12" md="3" lg="3">
                  <strong>Mínimo: </strong>{{minimo}}<br>
                  <strong>Reorden: </strong>{{reorden}}<br>
                  <strong>Máximo: </strong>{{maximo}}<br>
                  <strong>Existencia: </strong>{{existencia}}
                </v-col>
                <v-col sm="12" md="3" lg="3">
                  <strong>Consumo Mínimo: </strong>{{consumo_minimo}}<br>
                  <strong>Consumo Medio: </strong>{{consumo_medio}}<br>
                  <strong>Consumo Máximo: </strong>{{consumo_maximo}}<br>
                  <strong>Cantidad a Pedir: </strong>{{cantidad_pedir}}
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>

        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  name: "HistoricosComponent",
  props: ['props'],

  data() {
    return {
      menu1: false,
      menu2: false,
      menu3: false,
      menu4: false,
      model: {
        fecha_de: "",
        fecha_a: "",
        periodo: "1",
        id_articulo: "",
        tipo: 0,
        fecha_de_venta: "",
        fecha_a_venta: "",
        plazo: 0,
      },
      table: {
        columns: [
          "fecha",
          "cantidad",
          "precio",
          "total"
        ],
        options: {
          perPage: 10,
          pagination: {
            show: true
          },
          filterable: ["fecha", "cantidad", "precio"],
          // sortable: ["fecha", "cantidad", "precio", "total"],

          headings: {
            fecha: "Fecha",
            cantidad: "Cantidad",
            precio: "Precio",
            total: "Acciones"
          },
        }
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
      },
      items: [],
      items_compras: [],
      ultimos_vendidos: 0,
      total_compra: 0,
      promedio_compra: 0,
      precio_promedio_compra: 0,
      total_venta: 0,
      promedio_venta: 0,
      precio_promedio_venta: 0,
      minimo: 0,
      reorden: 0,
      maximo: 0,
      cantidad_pedir: 0,
      consumo_minimo: 0,
      consumo_medio: 0,
      consumo_maximo: 0,
      existencia: 0,
      articulo: {}
    }
  },
  methods: {
    calcular: async function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.model.id_articulo = this.props.id_articulo;
        let plazo = this.model.plazo;

        await this.get_existencia();

        let group_level = this.model.periodo == "1" ? "4" : this.model.periodo == "2" ? "3" : "2";

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
        const [year_de, month_de, day_de] = this.model.fecha_de_venta.split("-");
        const [year_a, month_a, day_a] = this.model.fecha_a_venta.split("-");
        let urlConditions = `&startkey=["${this.model.id_articulo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.model.id_articulo}", "${year_a}", "${month_a}", "${day_a}"]`;

        axios.get(url + urlConditions)
          .then(response => {
            if (response.data.rows.length > 0) {
              let respuesta = response.data.rows;
              let procesado = [];

              //Aqui ya seria agrupar por dia, semana o mes
              if (this.model.periodo == "1") {
                console.log("busqueda por dias")
                respuesta.forEach(x => {
                  let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                  procesado.push({
                    "fecha": key,
                    "cantidad": x.value.cantidad,
                    "precio": x.value.precio_venta / x.value.cantidad,
                    "total": 0
                  });
                });
              } else if (this.model.periodo == "2") { //if si es por mes

                console.log("busqueda por mes")
                respuesta.forEach(x => {
                  let key = `${x.key[1]}-${x.key[2]}`;

                  procesado.push({
                    "fecha": key,
                    "cantidad": x.value.cantidad,
                    "precio": x.value.precio_venta / x.value.cantidad,
                    "total": 0
                  });
                });

              } else { // if si es por año
                console.log("busqueda por año")
                respuesta.forEach(x => {
                  let key = `${x.key[1]}`;

                  procesado.push({
                    "fecha": key,
                    "cantidad": x.value.cantidad,
                    "precio": x.value.precio_venta / x.value.cantidad,
                    "total": 0
                  });
                });
              }

              //llenar los datos de ventas
              this.total_venta = 0;
              this.promedio_venta = 0;
              this.precio_promedio_venta = 0;

              let sorted = []
              procesado.forEach(x => {
                sorted.push(x);
                this.total_venta += x.cantidad;
                this.promedio_venta += x.cantidad;
                this.precio_promedio_venta += x.precio;
              });

              let numero_dias_tiempo = new Date(this.model.fecha_a_venta) - new Date(this.model.fecha_de_venta);
              let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
              if (this.model.periodo == "2") {
                // numero_dias = Math.ceil(numero_dias / 30);
              }
              if (this.model.periodo == "3") {
                // numero_dias = Math.ceil(numero_dias / 365);
              }
              this.promedio_venta = this.promedio_venta > 0 ? Math.ceil(this.promedio_venta / numero_dias) : 0;
              this.precio_promedio_venta = this.precio_promedio_venta > 0 ? this.precio_promedio_venta / procesado.length : 0;

              //Falta sacar los datos del articulo maximo, minimo, etc
              if (sorted.length > 0) {
                sorted.sort((a, b) => {
                  return a.cantidad - b.cantidad;
                });

                this.consumo_minimo = this.model.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                this.consumo_maximo = this.model.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (this.model.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));
                this.consumo_medio = this.promedio_venta;

                this.minimo = this.consumo_minimo * plazo;
                this.reorden = (this.consumo_medio * plazo) + this.minimo;
                this.maximo = (this.consumo_maximo * plazo) + this.minimo;

                this.cantidad_pedir = this.maximo - this.existencia;

              }

              this.items = procesado;
            } else {
              this.items = [];
            }
            window.dialogLoader.hide();
          })
          .catch(error => {
            console.log(error)
            window.dialogLoader.hide();
          })
      }
    },
    consultar_compras: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.model.id_articulo = this.props.id_articulo;

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/';
        let sort = "fecha";

        let data = {
          "selector": {
            "type": "compras",
            "articulos": {
              "$elemMatch": {
                "id_articulo": this.model.id_articulo
              }
            },
            "fecha": {
              "$gte": this.model.fecha_de,
              "$lte": this.model.fecha_a
            },
            "estatus": "Orden Finalizada"
          },
          "sort": [
            sort
          ],
          "limit": 1000000
        };

        axios({
            method: 'POST',
            url: url,
            withCredentials: true,
            data: data,
          }).then(response => {
            if (response.data.docs.length > 0) {
              let respuesta = response.data.docs;
              let dias = {};

              for (var i = 0; i < respuesta.length; i++) {
                let fecha = respuesta[i].fecha.toString().substring(0, 10);
                let articulos_filtrados = respuesta[i].articulos.filter(x => this.model.id_articulo == x.id_articulo);
                if (fecha in dias) {
                  for (var j = 0; j < articulos_filtrados.length; j++) {
                    dias[fecha].push({
                      "cantidad": articulos_filtrados[j].cantidad,
                      "precio": articulos_filtrados[j].precio_compra,
                      "total": 0
                    })
                  }
                } else {
                  dias[fecha] = [];

                  for (var j = 0; j < articulos_filtrados.length; j++) {
                    dias[fecha].push({
                      "cantidad": articulos_filtrados[j].cantidad,
                      "precio": articulos_filtrados[j].precio_compra,
                      "total": 0
                    })
                  }
                }
              }
              let procesado = [];
              //Aqui ya seria agrupar por dia, semana o mes
              for (const [key, value] of Object.entries(dias)) {
                let cantidad = 0;
                let precio = 0;

                for (var i = 0; i < value.length; i++) {
                  cantidad += value[i].cantidad;
                  precio += value[i].precio;
                }
                let promedio = precio == 0 ? 0 : (precio / value.length)
                procesado.push({
                  "fecha": key,
                  "cantidad": cantidad,
                  "precio": promedio,
                  "total": 0
                });
              }

              // Sacar los datos de la parte de abajo
              this.total_compra = 0;
              this.promedio_compra = 0;
              this.precio_promedio_compra = 0;

              procesado.forEach(x => {
                this.total_compra += x.cantidad;
                this.promedio_compra += x.cantidad;
                this.precio_promedio_compra += x.precio;
              });

              let numero_dias_tiempo = new Date(this.model.fecha_a) - new Date(this.model.fecha_de);
              let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);

              this.promedio_compra = this.promedio_compra > 0 ? Math.ceil(this.promedio_compra / numero_dias) : 0;
              this.precio_promedio_compra = this.precio_promedio_compra > 0 ? this.precio_promedio_compra / procesado.length : 0;

              this.items_compras = procesado;
            } else {
              this.items_compras = [];
            }
            window.dialogLoader.hide();
          })
          .catch(error => {
            console.log(error)
            window.dialogLoader.hide();
          })
      }
    },
    calcular_fecha: function(fecha) {
      this.model.fecha_de_venta = moment(String(fecha)).format("YYYY-MM-DD");;
      this.model.fecha_a_venta = moment().format("YYYY-MM-DD");

      this.model.tipo = 1;
      this.calcular();
    },
    get_existencia: async function() {
      let n = 0;

      await window.funciones_lotes.consultaExistencia({
        "id_articulo": this.model.id_articulo
      }).then(result => {
        if (result.length > 0) {
          result.forEach(el => {
            n += el.existencia
          });

          this.existencia = n;
        }
        console.log("se asigno la existencia");

      }).catch(err => {
        console.log("error", err);
        this.existencia = "Error";
      });
    },
    parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    valida_fecha: function(fecha) {
      if (fecha == "Invalid date")
        return "Fecha no válida."
      else
        return true;
    },
    cerrar: function() {
      this.items = [];
      this.items_compras = [];
      this.model = {
        fecha_de: "",
        fecha_a: "",
        periodo: "1",
        id_articulo: "",
        tipo: 0,
        fecha_de_venta: "",
        fecha_a_venta: "",
        plazo: 0
      };
      this.ultimos_vendidos = 0;
      this.total_compra = 0;
      this.promedio_compra = 0;
      this.precio_promedio_compra = 0;
      this.total_venta = 0;
      this.promedio_venta = 0;
      this.precio_promedio_venta = 0;
      this.props.modal = false;
      this.minimo = 0;
      this.reorden = 0;
      this.maximo = 0;
      this.cantidad_pedir = 0;
      this.consumo_minimo = 0;
      this.consumo_medio = 0;
      this.consumo_maximo = 0;
      this.existencia = 0;
      this.articulo = {};
    }
  }
}
</script>
