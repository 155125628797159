<template>
<div id="page-subastas" class="non-printable">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height class="non-printable">
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="Administrar Subastas" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="primary" @click.native="openModalSugeridos(1)" title="Buscar Sugeridos" v-tippy>
              <v-icon dark>mdi-cart-plus</v-icon>
            </v-btn>&nbsp;
            <v-btn fab dark x-small color="secondary" @click.native="openModal(1)" title="Crear Subasta" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid" v-on:keyup.enter="registros.items = []; $refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');">

              <v-client-table ref="vuetable"
                :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="fecha" slot-scope="props">
                        {{ parse_date_time(props.row.fecha) }}
                    </template>
                    <template slot="fecha_finalizada" slot-scope="props">
                        {{ parse_date_time(props.row.fecha_finalizada) }}
                    </template>
                    <template slot="articulos" slot-scope="props">
                        <v-chip color="blue" label outlined x-small @click="verArticulos(props.row)">
                            Ver {{ props.row.articulos.length }} artículos
                        </v-chip>
                    </template>
                    <template slot="actions" slot-scope="props">
                        <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row._id]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>

                            <v-btn x-small fab dark color="black"
                                v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(props.row.estatus)"
                                @click.native="showAsignarProveedor(props.row)" title="Proveedores" v-tippy>
                                <v-icon>supervisor_account</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="green"
                                @click.native="vistaPreviaExcel(props.row)" title="Excel Subasta" v-tippy>
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="orange"
                                @click.native="vistaPreviaProveedoresExcel(props.row)" title="Excel Proveedores" v-tippy>
                                <v-icon>mdi-file-excel</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="blue darken-2"
                                @click.native="cambiarEstatus(props.row)" title="Editar Estatus" v-tippy>
                                <v-icon>mdi-more</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="red"
                                v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(props.row.estatus)"
                                @click.native="eliminarOrden(props.row)" title="Eliminar Subasta" v-tippy>
                                <v-icon>delete</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="purple darken-1"
                                @click.native="editarObservaciones(props.row)" title="Observaciones" v-tippy>
                                <v-icon>mdi-note-plus</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="blue"
                                @click.native="modalAdjuntar(props.row)" title="Adjuntar Documentos" v-tippy>
                                <v-icon>attach_file</v-icon>
                            </v-btn>
                        </v-speed-dial>

                    </template>

                </v-client-table>
                <PaginacionComponent ref="pagination_pw" :props="props_paginacion"></PaginacionComponent>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <!-- MODAL BUSQUEDA -->
  <v-dialog v-model="modalBusqueda" max-width="100%">
    <v-card>
      <!--v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Información de Artículos</strong>
          </span>
        </v-card>
       <v-spacer></v-spacer>
        <v-btn icon @click.native="modalBusqueda = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title-->
      <v-card-text>

          <!--v-container grid-list-md id="modalbusqueda"-->
                <v-tabs id="modalbusqueda" grow style="margin-left:0px;" v-model="active_tab">
                    <v-tabs-slider color="#EEEEEE"></v-tabs-slider>

                    <v-tab key="busqueda">
                        <v-card-title><b>Buscar Artículos</b></v-card-title>
                    </v-tab>
                    <v-tab key="pedido" @click="ver_subasta()">
                        <v-card-title><b>Subasta Abierta</b></v-card-title>
                    </v-tab>

                    <v-tab-item key="busqueda" style="margin-left:10px;margin-right:0px;">
                        <v-row dense>
                            <v-col sm="10" md="10" lg="10" v-show="modal_opcion==2"> 
                                <div id="combos_sugeridos">
                                    <v-row id="row_combos_sugeridos">
                                        <v-col sm="9" md="9" lg="9">
                                            <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                                                ref="selectproveedor" :loading="loadingProveedores"
                                                placeholder="Ingrese nombre de proveedor"
                                                prepend-icon="search" clearable item-text="nombre" item-value="_id"
                                                @change="consultarSugeridos()" label="Proveedor">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="3" md="3" lg="3">
                                            <v-spacer></v-spacer>
                                            <v-btn color="success" small v-if="data_sugeridos.length>0" v-tippy @click="agregarTodosSugerido()">
                                                <v-icon>mdi-ok</v-icon>Agregar Todos a la Subasta
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>                               
                                <div id="tableBuscarSugeridos" style="height: 47vh; overflow-y: scroll; overflow-x: scroll;">
                                    
                                <v-client-table ref="vuetablearticulossugeridos" :data="data_sugeridos" :columns="columnsArticulosSugeridos"
                                    :options="optionsArticulosSugeridos" class="elevation-2 pa-2">
                                    <template slot="articulo.nombre" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:12px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.nombre}}</div>
                                    </template>
                                    <template slot="articulo.descripcion" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.descripcion}}</div>
                                    </template>
                                    <template slot="articulo.categoria" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.articulo.categoria.join(", ")}}</div>
                                    </template>
                                    <template slot="minimo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.minimo}}</div>
                                    </template>
                                    <template slot="reorden" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.reorden}}</div>
                                    </template>
                                    <template slot="maximo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.maximo}}</div>
                                    </template>
                                    <template slot="actual" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{parseFloat(props.row.actual).toFixed(2)}}</div>
                                    </template>
                                    <template slot="pedir" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="agregarSugerido(props.row)">{{props.row.pedir}}</div>
                                    </template>
                                    <template slot="actions" slot-scope="props">
                                        <v-icon small color="success"
                                            v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                            title="Artículo Agregado" >check_circle
                                        </v-icon>
                                    </template>
                                </v-client-table>
                                </div>
                                
                            </v-col>
                            <v-col sm="10" md="10" lg="10" v-show="modal_opcion==1" >
                                <div id="combos_busqueda">
                                    <v-row id="row_combos">
                                        <v-col sm="4" md="4" lg="4">
                                            <v-autocomplete dense v-model="id_articulo" :items="articulos" :hide-no-data="true"
                                            :loading="loadingArticulos" ref="selectarticulo" placeholder="Ingrese nombre de artículo"
                                            prepend-icon="search" clearable item-text="nombre" item-value="_id"
                                            @change="consultarArticulo()" label="Artículo">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="4" md="4" lg="4">
                                            <v-autocomplete dense v-model="id_proveedor" :items="proveedores_combo" :hide-no-data="true"
                                            :loading="loadingProveedores"
                                            ref="selectproveedor" placeholder="Ingrese nombre de proveedor" prepend-icon="search" clearable
                                            item-text="nombre" item-value="_id" @change="consultarProveedor_combo()" label="Proveedor">
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col sm="4" md="4" lg="4">
                                            <v-autocomplete dense v-model="nombre_categoria"
                                            :items="categorias" :hide-no-data="true" :loading="loadingCategorias"
                                            ref="selectcategoria" placeholder="Ingrese nombre de categoría"
                                            prepend-icon="search" clearable item-text="nombre" item-value="nombre"
                                            @change="consultarCategoria()" label="Categoría">
                                            </v-autocomplete>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div id="tableBuscar" style="height: 47vh; overflow-y: scroll; overflow-x: scroll;">
                                    
                                <v-client-table ref="vuetablearticulos" :data="articulos_encontrados"
                                    :columns="columnsArticulos" :options="optionsArticulos">
                                    <template slot="articulo.nombre" slot-scope="props">
                                        <div :class="'columnaArticulo celda_'+props.row.articulo._id" style="font-size:12px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.nombre}}</div>
                                    </template>
                                    <template slot="articulo.descripcion" slot-scope="props">
                                        <div :class="'columnaUnidad celda_'+props.row.articulo._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.descripcion}}</div>
                                    </template>
                                    <template slot="articulo.categoria" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.categoria.join(", ")}}</div>
                                    </template>
                                    <template slot="articulo.minimo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.minimo}}</div>
                                    </template>
                                    <template slot="articulo.reorden" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.reorden}}</div>
                                    </template>
                                    <template slot="articulo.maximo" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.articulo.maximo}}</div>
                                    </template>
                                    <template slot="articulo.actual" slot-scope="props">
                                        <div :class="'celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{formatNumberDec(props.row.articulo.actual,2)}}</div>
                                    </template>
                                    <template slot="articulo.precio_compra_con_iva" slot-scope="props">
                                        <div :class="'columnaPrecioCompra celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">${{formatNumberDec(props.row.articulo.precio_compra_con_iva,2)}}</div>
                                    </template>
                                    <template slot="articulo.precio_venta" slot-scope="props">
                                        <div :class="'columnaPrecioVenta celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">${{formatNumberDec(props.row.articulo.precio_venta,2)}}</div>
                                    </template>
                                    <template slot="articulo.ventas_30_dias" slot-scope="props">
                                        <div :class="'columnaVentas30 celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{parseFloat(props.row.articulo.ventas_30_dias).toFixed(2)}}</div>
                                    </template>
                                    <template slot="fvm" slot-scope="props">
                                        <div :class="'columnaFVM celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">                                       
                                        <span style="color:#DC7633" v-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=0 && (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))<50">
                                            <strong>{{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}</strong>
                                        </span>
                                        <span style="color:red" v-else-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=50">
                                            <strong>{{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}</strong>
                                        </span>
                                        <span style="color:green" v-else>
                                            {{(parseFloat(props.row.articulo.actual_total) - parseFloat(props.row.articulo.ventas_30_dias)).toFixed(2)}}
                                        </span>                                        
                                        </div>
                                    </template>                                    
                                    <template slot="fvm2" slot-scope="props">
                                        <div :class="'columnaFVM2 celda_'+props.row.articulo._id" style="font-size:11px !important;text-align:center;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">
                                        <span style="color:#DC7633" v-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=0 && (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))<50">
                                            <strong>{{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%</strong>
                                        </span>
                                        <span style="color:red" v-else-if="(((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1))>=50">
                                            <strong>{{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%</strong>
                                        </span>
                                        <span style="color:green" v-else>
                                            {{ (((parseFloat(props.row.articulo.ventas_30_dias) - parseFloat(props.row.articulo.actual_total))*100)/(parseFloat(props.row.articulo.ventas_30_dias)>0?parseFloat(props.row.articulo.ventas_30_dias):1)).toFixed(2) }}%
                                        </span>
                                            
                                        </div>
                                    </template>
                                    <template slot="proveedor.nombre" slot-scope="props">
                                        <div :class="'columnaProveedor celda_'+props.row.articulo._id" style="font-size:11px !important;"
                                        @click="mostrarInformacion(props.row)"
                                        @dblclick="ingresarCantidad(props.row)">{{props.row.proveedor.nombre}}</div>
                                    </template>

                                    <template slot="actions" slot-scope="props">
                                        <v-icon small color="success"
                                            v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                            title="Artículo Agregado" >check_circle
                                        </v-icon>
                                    </template>

                                </v-client-table>
                                </div>
                                
                            </v-col>
                            <v-col sm="2" lg="2" md="2">
                                <fieldset>
                                    <legend> &nbsp;&nbsp;&nbsp;<v-btn outlined x-small dark color="green">Exist. Total:&nbsp; <strong>{{formatNumberDec(existencia_articulo,0)}}</strong>  </v-btn></legend>
                                    <div id="tableExistencias" style="height: 20vh; overflow-y: scroll; overflow-x: scroll;">

                                    <v-client-table ref="vuetableexistencias" :data="articulo_existencias"
                                        :columns="columnsExistencias" :options="optionsExistencias">
                                        <template slot="sucursal" slot-scope="props">
                                            <div style="font-size:10px !important;" >{{props.row.sucursal}}</div>
                                        </template>
                                        <template slot="existencia" slot-scope="props">
                                            <div style="font-size:10px !important;" >{{formatNumberDec(props.row.existencia,2)}}</div>
                                        </template>
                                    </v-client-table>
                                    </div>
                                </fieldset>

                                <fieldset>                                
                                    <div id="tablePrecios" style="height: 17vh; overflow-y: scroll; overflow-x: scroll; margin-bottom:-5px; padding-bottom:-5px;">
                                    <v-client-table ref="vuetablePrecios" :data="precios_articulo"
                                        :columns="columnsPrecios" :options="optionsPrecios">
                                        <template slot="tipo_precio" slot-scope="props">
                                            <div style="font-size:10px !important;">{{props.row.tipo_precio}}</div>
                                        </template>
                                        <template slot="precio_con_impuestos" slot-scope="props">
                                            <div style="font-size:10px !important;text-align:center;">${{ formatNumberDec(props.row.precio_con_impuestos,2) }}</div>
                                        </template>
                                        <template slot="de" slot-scope="props">
                                            <div style="font-size:10px !important;">{{props.row.de}}</div>
                                        </template>
                                        <template slot="a" slot-scope="props">
                                            <div style="font-size:10px !important;">{{props.row.a}}</div>
                                        </template>

                                        <template slot="actions" slot-scope="props">
                                            <v-icon small color="success"
                                                v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                                title="Artículo Agregado" >check_circle
                                            </v-icon>
                                        </template>
                                    </v-client-table>
                                    </div>
                                </fieldset><br>

                                <div style="text-align: center !important;">
                                    <v-btn small @click.native="modalBusqueda=false">
                                        <v-icon>cancel</v-icon> Cerrar
                                    </v-btn>
                                </div>

                            </v-col>


                        </v-row>

                        
                            <fieldset>
                            <div id="filtroPeriodo" style="height: 10vh; margin-bottom:-5px !important; padding-bottom:-5px;">
                                <v-form ref="form" lazy-validation>
                                <v-row style="margin-bottom:-5px !important; padding-bottom:-5px !important;">
                                    <v-col sm="12" md="2" lg="2">
                                        <v-menu offset-x ref="menu3" v-model="menu3" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-on="on" v-model="fecha_de_venta" label="Fecha Inicio" color="secondary" prepend-icon="event" @blur="fecha_de_venta = parse_date(fecha_de_venta)" :rules="[rules.required, valida_fecha]">
                                            </v-text-field>
                                        </template>

                                        <v-date-picker v-model="fecha_de_venta" color="secondary" scrollable locale="es-mx" @input="menu3 = false">
                                            <v-spacer />

                                            <v-btn color="secondary" x-small @click="menu3 = false">
                                            Cancelar
                                            </v-btn>
                                        </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col sm="12" md="2" lg="2">
                                        <v-menu offset-x ref="menu4" v-model="menu4" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-on="on" v-model="fecha_a_venta" label="Fecha Fin" color="secondary" prepend-icon="event" @blur="fecha_a_venta = parse_date(fecha_a_venta)" :rules="[rules.required, valida_fecha]">
                                            </v-text-field>
                                        </template>

                                        <v-date-picker v-model="fecha_a_venta" color="secondary" scrollable locale="es-mx" @input="menu4 = false">
                                            <v-spacer />

                                            <v-btn color="secondary" x-small @click="menu4 = false">
                                            Cancelar
                                            </v-btn>
                                        </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col sm="12" md="3" lg="3">
                                        <v-radio-group dense v-model="periodo" row :rules="[rules.required]">
                                        <template v-slot:label>
                                            <div>Periodo</div>
                                        </template>
                                        <v-radio label="Día" value="1"></v-radio>
                                        <v-radio label="Mes" value="2"></v-radio>
                                        <v-radio label="Año" value="3"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                    <v-col sm="12" md="1" lg="1">
                                        <v-text-field label="Plazo" v-model="plazo_calculo" :rules="[rules.required]"></v-text-field>                                                                                        
                                    </v-col>
                                    <v-col sm="12" md="2" lg="2">    
                                        <br>                                        
                                        <v-btn v-show="id_articulo_calculo!=''" x-small color="info"
                                            @click.native="calcular_compras_ventas()"
                                            >Calcular</v-btn>
                                    </v-col>                                        
                                    
                                </v-row>
                                </v-form>
                                
                            </div>
                            </fieldset>                                                    

                        <v-row dense>
                            <v-col sm="6" lg="6" md="6">                                
                                <span><strong>Compras</strong> <v-btn v-show="loadingCompras" fab x-small color="gray" :loading="loadingCompras">...</v-btn></span>
                                <div id="tableCompras" style="height: 15vh; overflow-y: scroll; overflow-x: scroll;">
                                <v-client-table ref="vuetable_compras" :data="items_compras" :columns="compras.columns" :options="compras.options">
                                    <template slot="fecha" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.fecha}}</div>
                                    </template>
                                    <template slot="existencia" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{formatNumberDec(props.row.existencia,2)}}</div>
                                    </template>
                                    <template slot="cantidad" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.cantidad}}</div>
                                    </template>
                                    <template slot="precio" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">${{ formatNumberDec(props.row.precio,2)}}</div>
                                    </template>
                                    <template slot="proveedor" slot-scope="props">
                                        <div class="columnaProveedor" style="font-size:10px !important;">{{props.row.proveedor}}</div>
                                    </template>
                                    <template slot="orden" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center">{{props.row.orden}}</div>
                                    </template>
                                    <template slot="total" slot-scope="props">
                                        <div style="font-size:10px !important; text-align:center">

                                            <v-icon small dark color="blue" @click.native="calcular_fecha(props.row.fecha)" title="Calcular">refresh</v-icon>

                                        </div>
                                    </template>

                                    <template slot="actions" slot-scope="props">
                                        <v-icon small color="success"
                                            v-show="articuloEnOrden(props.row.articulo._id, props.row.proveedor._id)"
                                            title="Artículo Agregado" >check_circle
                                        </v-icon>
                                    </template>

                                </v-client-table>
                                </div>
                                    <span title="Es la cantidad total comprada en el periodo seleccionado"><strong>Cantidad Comprada del Periodo: </strong>{{total_compra.toFixed(4)}}</span><br>
                                    <strong>Cantidad Promedio Diaria: </strong>{{promedio_compra}}<br>
                                    <strong>Precio Promedio: </strong>${{precio_promedio_compra.toFixed(4)}}                                
                            </v-col>
                            <v-col sm="6" lg="6" md="6">                                
                                <span><strong>Ventas</strong> <v-btn v-show="loadingVentas" fab x-small color="gray" :loading="loadingVentas">...</v-btn></span>
                                <div id="tableVentas" style="height: 15vh; overflow-y: scroll; overflow-x: scroll;">
                                <v-client-table ref="vuetable_ventas" :data="items_ventas" :columns="ventas.columns" :options="ventas.options">
                                    <template slot="fecha" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.fecha}}</div>
                                    </template>
                                    <template slot="cantidad" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">{{props.row.cantidad}}</div>
                                    </template>
                                    <template slot="precio" slot-scope="props">
                                        <div style="font-size:10px !important;text-align:center;">${{formatNumberDec(props.row.precio,2)}}</div>
                                    </template>

                                </v-client-table>
                                </div>
                                <v-row>
                                    <v-col sm="12" md="4" lg="4">
                                    <span title="Es la cantidad total vendida en el periodo seleccionado"><strong>Cantidad Vendida del Periodo: </strong>{{total_venta.toFixed(4)}}</span><br>
                                    <strong>Cantidad Promedio Diaria: </strong>{{promedio_venta}}<br>
                                    <strong>Precio Promedio: </strong>${{precio_promedio_venta.toFixed(4)}}
                                    </v-col>
                                    <v-col sm="12" md="4" lg="4">
                                    <span :title="'Promedio x Plazo = '+promedio_venta+' x '+plazo_calculo"><strong>Mínimo: </strong>{{minimo}}</span><br>
                                    <span :title="'Promedio x Plazo + Minimo = '+promedio_venta+' x '+plazo_calculo+' + '+minimo"><strong>Reorden: </strong>{{reorden}}</span><br>
                                    <span :title="'Promedio x (Plazo + 30 dias) + Minimo = '+promedio_venta+' x ('+(parseInt(plazo_calculo)+30)+') + '+minimo+'  (Suponiendo que se quiere surtir durante 30 días)'"><strong>Máximo: </strong>{{maximo}}</span><br>  
                                    </v-col>
                                    <v-col sm="12" md="4" lg="4">
                                    <strong>Existencia: </strong>{{existencia_articulo}}<br>
                                    <!--strong>Consumo Mínimo: </strong>{{consumo_minimo}}<br>
                                    <strong>Consumo Medio: </strong>{{consumo_medio}}<br>
                                    <strong>Consumo Máximo: </strong>{{consumo_maximo}}<br-->
                                    <span :title="'Máximo - Existencia = '+maximo+' - '+existencia_articulo"><strong>Cantidad a Pedir: </strong>{{cantidad_pedir}}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item key="pedido" style="margin-left:10px;margin-right:10px;">
                        <v-card>                          
                            <v-card-text>
                                <v-container grid-list-md>
                                    <v-row>
                                        <v-col>
                                            <span style="font-weight:bold;color:red">{{mensaje_tab_pedido}}</span>
                                            <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos" :columns="columnsArticulosVistaProveedores" 
                                                :options="optionsArticulosVistaProveedores" class="elevation-2 pa-2" :key="keyTableArticulosAbierta">
                                                <template slot="codigo_articulo" slot-scope="props" >
                                                    <div :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                    {{ props.row.codigo_articulo.join(", ") }}
                                                    </div>
                                                </template>

                                                <template slot="nombre_articulo" slot-scope="props" >
                                                    <div :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                    {{ props.row.nombre_articulo }}
                                                    </div>
                                                </template>

                                                <template slot="descripcion" slot-scope="props" >
                                                    <div :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                    {{ props.row.descripcion }}
                                                    </div>
                                                </template>

                                                <template slot="existencia" slot-scope="props">
                                                    <div style="text-align:center;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                        {{ formatNumberDec(props.row.existencia,2) }}
                                                    </div>
                                                </template>

                                                <template slot="cantidad" slot-scope="props">
                                                    <div style="text-align:center" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                        {{ props.row.cantidad }}
                                                    </div>
                                                </template>

                                                <template slot="precio_compra_con_iva" slot-scope="props">
                                                    <div style="text-align:center" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                        ${{ formatNumberDec(props.row.precio_compra_con_iva,2) }}
                                                    </div>
                                                </template>

                                                <template slot="precio_nuevo" slot-scope="props">
                                                    <div style="text-align:center" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                        ${{ formatNumberDec(props.row.precio_nuevo,2) }}
                                                    </div>
                                                </template>

                                                <template slot="observaciones" slot-scope="props" >
                                                    <div :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                                    {{ props.row.observaciones?props.row.observaciones:'&nbsp;' }}
                                                    </div>
                                                </template>

                                                <template slot="actions" slot-scope="props">
                                                    <v-speed-dial direction="left" open-on-hover>
                                                    <template v-slot:activator>
                                                    <v-btn x-small fab dark color="primary">
                                                        <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                                        <v-icon v-else>reorder</v-icon>
                                                    </v-btn>
                                                    </template>
                                                        <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo, props.row.resaltado)"
                                                            v-show="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                                                            title="Descartar Artículo" v-tippy>
                                                            <v-icon>delete</v-icon>
                                                        </v-btn>
                                                        &nbsp;
                                                        <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)"
                                                            v-show="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                                                            title="Editar Cantidad" v-tippy>
                                                            <v-icon>edit</v-icon>
                                                        </v-btn>
                                                    </v-speed-dial>
                                                </template>
                                            </v-client-table>
                                        </v-col>
                                    </v-row>
                                </v-container>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn small @click.native="modalBusqueda=false">
                                    <v-icon>cancel</v-icon> Cerrar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-tab-item>
                </v-tabs>
                        
                        


            <!--/v-container-->

      </v-card-text>      
    </v-card>
  </v-dialog>

  <!-- MODAL ARTICULOS -->
  <v-dialog v-model="modalArticulos" max-width="85%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Subasta</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalArticulos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
          <v-container grid-list-md id="modal_articulos">
              <v-row>
                <v-col lg="4" md="4" sm="4">
                    <b>Estatus:</b> {{model_vista.estatus}}
                </v-col>
                <v-col lg="8" md="8" sm="8" v-show="model_vista.observaciones!=null && model_vista.observaciones!=''">
                    <b>Observaciones de Subasta:</b><br>
                    {{model_vista.observaciones}}
                </v-col>
              </v-row><br>
            <br>
            <v-row>
                <v-col>
                    <v-btn small dark color="blue darken-4" @click="elegirProveedorMenorPrecio()"
                        v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                        title="Seleccionar precios más bajos" v-tippy>
                        <v-icon>check_circle_outline</v-icon> Seleccionar precios más bajos
                    </v-btn>
                    <v-client-table ref="vuetablearticulosorden" :data="model_vista.articulos" :columns="columnsArticulosVistaProveedores" 
                        :options="optionsArticulosVistaProveedores" class="elevation-2 pa-2" :key="keyTableArticulos">
                        
                        <template slot="codigo_articulo" slot-scope="props" >
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ props.row.codigo_articulo.join(", ") }}
                            </div>
                        </template>

                        <template slot="nombre_articulo" slot-scope="props" >
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ props.row.nombre_articulo }}
                            </div>
                        </template>

                        <template slot="descripcion" slot-scope="props" >
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                            {{ props.row.descripcion }}
                            </div>
                        </template>

                        <template slot="existencia" slot-scope="props">
                            <div style="font-size:10px;text-align:center;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ formatNumberDec(props.row.existencia,2) }}
                            </div>
                        </template>

                        <template slot="cantidad" slot-scope="props">
                            <div style="font-size:10px;text-align:center;font-weight:bold;"  :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ props.row.cantidad }}
                            </div>
                        </template>

                        <template slot="precio_compra_con_iva" slot-scope="props">
                            <div style="font-size:10px;text-align:center;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                ${{ formatNumberDec(props.row.precio_compra_con_iva,2) }}
                            </div>
                        </template>

                        <template slot="precio_nuevo" slot-scope="props">
                            <div style="font-size:10px;text-align:center;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                ${{ formatNumberDec(props.row.precio_nuevo,2) }}
                            </div>
                        </template>

                        <template slot="observaciones" slot-scope="props">
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ props.row.observaciones?props.row.observaciones:'&nbsp;' }}
                            </div>
                        </template>

                        <template v-for="(val,index) in columnasProveedores" :slot="val" slot-scope="props">
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')"
                            v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)">
                            <v-checkbox v-if="getPrecioProveedor(props.row.id_articulo, props.row.resaltado, val)!=''" class="compact"
                            @change="elegirProveedor(props.row.id_articulo, props.row.resaltado, val)"
                            :label="'$'+formatNumberDec(getPrecioProveedor(props.row.id_articulo, props.row.resaltado, val),4)"
                            :input-value="parseInt(getElegidoProveedor(props.row.id_articulo, props.row.resaltado, val))"
                            :readonly="getElegidoProveedor(props.row.id_articulo, props.row.resaltado, val)==1?true:false"
                            ></v-checkbox>
                            <span v-else>&nbsp;</span>
                            </div>
                            <div v-else style="text-align:center;font-size:10px;font-weight:bold;"  :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                ${{formatNumberDec(getPrecioProveedor(props.row.id_articulo, props.row.resaltado, val),4)}}&nbsp;
                            </div>
                        </template>

                        <template v-for="(val,index) in columnasProveedores" :slot="'observaciones-'+val" slot-scope="props">
                            <div style="font-size:10px;font-weight:bold;" :class="(props.row.resaltado==1?'celda_vuetable_resaltado':'')">
                                {{ getObsProveedor(props.row.id_articulo, props.row.resaltado, val) }}&nbsp;
                            </div>
                        </template>

                        <template slot="actions" slot-scope="props">
                            <v-speed-dial direction="left" open-on-hover>
                            <template v-slot:activator>
                            <v-btn x-small fab dark color="primary">
                                <v-icon v-if="fab[props.row.id_articulo]">mdi-close</v-icon>
                                <v-icon v-else>reorder</v-icon>
                            </v-btn>
                            </template>
                                <v-btn x-small fab dark color="pink" @click.native="deleteArticulo(props.row.id_articulo, props.row.resaltado)"
                                    v-show="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                                    title="Descartar Artículo" v-tippy>
                                    <v-icon>delete</v-icon>
                                </v-btn>
                                &nbsp;
                                <v-btn x-small fab dark color="teal darken-1" @click.native="editArticulo(props.row)"
                                    v-show="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                                    title="Editar Cantidad" v-tippy>
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </v-speed-dial>
                        </template>
                    </v-client-table>
                </v-col>
            </v-row>
          </v-container>

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small dark color="success" @click="generarOrdenesCompra()"
            v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
            title="Finalizar Subasta" v-tippy>
            <v-icon>done_all</v-icon> Finalizar/Generar Órdenes de Compra
        </v-btn> &nbsp;
        <v-btn @click.native="modalArticulos=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL EDITAR ARTICULO -->
  <v-dialog v-model="modalEditable" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Artículo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formeditable" lazy-validation>
          <v-container grid-list-md>
              <v-row>
                <v-col sm="12" md="12" lg="12">
                    <v-text-field label="Articulo" v-model="articulo_editable.nombre_articulo" readonly ></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col sm="2" md="2" lg="2"></v-col>
                <v-col sm="4" md="4" lg="4">
                    <v-text-field label="Último Precio Compra C/IVA" v-model="articulo_editable.precio_compra_con_iva" 
                    disabled readonly
                    :rules="[rules.required, validaCantidadFloatRequerido]"></v-text-field>
                </v-col>
                <v-col sm="4" md="4" lg="4">
                    <v-text-field label="Cantidad" v-model="articulo_editable.cantidad" :rules="[rules.required, validaCantidadFloatRequerido]"></v-text-field>
                </v-col>
                <v-col sm="2" md="2" lg="2"></v-col>
            </v-row>
            <v-row>
                <v-col sm="2" md="2" lg="2"></v-col>
                <v-col sm="8" md="8" lg="8">
                    <v-text-field label="Observaciones" v-model="articulo_editable.observaciones"></v-text-field>
                </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalEditable=false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="saveNuevaCantidadArticulo()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL OBSERVACION -->
  <v-dialog v-model="modalObservacion" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Observaciones</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon class="close_modal">
          <v-icon class="white--text"></v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formobservacion" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-textarea outlined label="Observaciones de Subasta" rows="4" v-model="observaciones_editable" ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalObservacion=false">
          <v-icon>cancel</v-icon> Cerrar
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" @click.native="guardarObservaciones()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCantidad" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cantidad a Ordenar</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCantidad = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcantidad" lazy-validation >
          <v-container>
            <v-row justify="center">
              <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                <v-autocomplete :items="sucursales_tipo" v-model="sucursal_seleccionada"
                    item-text="nombre" return-object :rules="[rules.required]"
                    placeholder="Seleccione sucursal" label="Sucursal">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col sm="6" md="6" lg="6" style="text-align:center !important;">
                <v-text-field label="Cantidad" v-model="cantidad_editable" ref="ingresacantidad"
                    v-on:keydown.enter.prevent='1' @keyup.enter="validarArticulo()"
                  :rules="[validaCantidadFloatRequerido]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn  @click.native="modalCantidad = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn  color="success" @click.native="validarArticulo()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalEstatus" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">edit</v-icon>&nbsp;
          <span class="subheading">
            <strong>Editar Estatus</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalEstatus = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcantidad" lazy-validation >
          <v-container>
            <v-row justify="center">
              <v-col sm="10" md="10" lg="10" style="text-align:center !important;">
                <v-autocomplete v-model="estatus_editable" :items="listColumns.estatus" :hide-no-data="true"
                    item-text="id" item-value="id" label="Seleccione Estatus" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn small @click.native="modalEstatus = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn small color="success" @click.native="changeEstatus()">
                <v-icon>done</v-icon> Aceptar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCorreo" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">email</v-icon>&nbsp;
          <span class="subheading">
            <strong>Enviar por Correo</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCorreo = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formcorreo" lazy-validation >
          <v-container>
            <v-row>
              <v-col>
                <v-text-field label="Enviar a" v-model="correo.correos"
                    persistent-hint hint="Correos separados por coma"
                    :rules="[rules.required, validaCorreo]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Asunto" v-model="correo.asunto" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Texto del Correo" v-model="correo.mensaje" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field label="Responder a" v-model="correo.responder_a"
                persistent-hint hint="Correos separados por coma"
                :rules="[rules.required, validaCorreo]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row justify="center">
              <v-btn small @click.native="modalCorreo = false">
                <v-icon>cancel</v-icon> Cancelar
              </v-btn>
              &nbsp;&nbsp;
              <v-btn small color="success" @click.native="enviarCorreo()">
                <v-icon>done</v-icon> Enviar
              </v-btn>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalAdjuntarDocumentos" max-width="55%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">attach_file</v-icon>&nbsp;
          <span class="subheading">
            <strong>Adjuntar Documentos</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAdjuntarDocumentos = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formadjuntos" lazy-validation >
          <v-container>
            <v-row>
              <v-col lg="9" md="9" sm="9">
                    <v-file-input label="Seleccione un archivo"
                        :rules="[rules.required]"
                        v-model="file_adjunto"
                        :show-size="1000"
                    ></v-file-input>
              </v-col>
              <v-col lg="3" md="3" sm="3">
                  <br/>
                  <v-btn small color="info" @click.native="adjuntarArchivo()">
                    Adjuntar
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              &nbsp;
            </v-row>
            <v-row>
              <v-col>
                  <v-client-table  :data="model_adjuntos" :columns="columnsAdjuntos" :options="optionsAdjuntos" class="elevation-2 pa-2">
                    <template slot="actions" slot-scope="props">
                        <v-btn x-small fab dark color="success" @click.native="descargarAdjunto(props.row)"
                            title="Descargar Adjunto" v-tippy>
                            <v-icon>file_download</v-icon>
                        </v-btn>
                        &nbsp;
                        <v-btn x-small fab dark color="pink" @click.native="eliminarAdjunto(props.row)"
                            title="Eliminar Adjunto" v-tippy>
                            <v-icon>delete</v-icon>
                        </v-btn>
                    </template>
                </v-client-table>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>

       <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small @click.native="modalAdjuntarDocumentos = false">
        <v-icon>cancel</v-icon> Cancelar
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>


  <v-dialog v-model="modalVistaPrevia" max-width="60%">
    <v-card>
        <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-excel</v-icon>&nbsp;
          <span class="subheading">
            <strong>Excel de Subasta</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalVistaPrevia = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="info" small @click.native="modalCorreo=true">
          <v-icon>email</v-icon> Enviar por Correo
        </v-btn>
        &nbsp;&nbsp;
        <v-btn color="success" small @click.native="descargaExcel()">
          <v-icon>mdi-file-excel</v-icon> Exportar a Excel
        </v-btn>
        &nbsp;&nbsp;
        <v-btn small  @click.native="modalVistaPrevia=false">
          <v-icon>close</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
      <hr>
      <v-card-text>
        <div id="orden_excel" v-if="model_vista != null">
            <meta charset="utf-8" >

            <table width="100%">
                <tr>
                    <td colspan="6" style="color:red;font-weight:bolder;text-align:center;">
                        LA BODEGUITA DE GUADALUPE
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="font-weight:bolder;text-align:center">
                        {{ "SUBASTA " + parse_date_es(model_vista.fecha) }}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="font-weight:bolder;text-align:center">
                        No. Subasta: {{model_vista.no_subasta}}
                    </td>
                </tr>
                <tr><td colspan="6" style="font-weight:bolder;text-align:center">&nbsp;</td></tr>
            </table>

            <table border=1 width="100%">
                <tr>
                    <th class="celda_titulo" width="12%">CÓDIGO BARRAS</th>
                    <th class="celda_titulo" width="28%">NOMBRE</th>
                    <th class="celda_titulo" width="12%">DESCRIPCIÓN</th>
                    <th class="celda_titulo" width="14%">PEDIDO</th>
                    <th class="celda_titulo" width="14%">$ NUEVO</th>
                    <th class="celda_titulo" width="20%">OBS</th>
                </tr>

                <tr v-for="(articulo, index) in model_vista.articulos">
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{ articulo.codigo_articulo[0] }}</td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.nombre_articulo}}</td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.descripcion}}</td>
                    <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.cantidad}}</td>
                    <td style="text-align:right;"
                        :class="(articulo.precio_proveedor!=null && articulo.precio_proveedor!=''
                            && articulo.precio_proveedor!=articulo.precio_compra_con_iva?'celda_datos bg-seleccionado':
                            articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >$</td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                    ></td>
                </tr>
            </table>

            <table width="100%">
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr>
                    <td></td>
                    <td colspan="4" style="font-weight:bolder;">
                        Observaciones: {{model_vista.observaciones}}
                    </td>
                    <td></td>
                </tr>
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr><td colspan="6">&nbsp;</td></tr>
            </table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  
      <v-dialog v-model="modalVistaPreviaProveedores" max-width="85%">
    <v-card>
        <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-excel</v-icon>&nbsp;
          <span class="subheading">
            <strong>Excel de Subasta con Proveedores</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalVistaPreviaProveedores = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="primary" small @click="showCargarExcel()"
            v-if="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)">
          <v-icon>mdi-file-import</v-icon> Cargar Excel de Proveedor
        </v-btn>

        &nbsp;&nbsp;
        <v-btn color="success" small @click.native="descargaExcel()">
          <v-icon>mdi-file-excel</v-icon> Exportar a Excel
        </v-btn>

        &nbsp;&nbsp;
        <v-btn small  @click.native="modalVistaPreviaProveedores=false">
          <v-icon>close</v-icon> Cerrar
        </v-btn>
      </v-card-actions>
      <hr>
      <v-card-text>
        
        <div id="orden_excel_proveedores" v-if="model_vista != null" class="dialog-content">
            <meta charset="utf-8" >

            <table width="100%">
                <tr>
                    <td colspan="6" style="color:red;font-weight:bolder;text-align:center;">
                        LA BODEGUITA DE GUADALUPE
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="font-weight:bolder;text-align:center">
                        {{ "SUBASTA CORRESPONDIENTE AL " + parse_date_es(model_vista.fecha) }}
                    </td>
                </tr>
                <tr>
                    <td colspan="6" style="font-weight:bolder;text-align:center">
                        No. Subasta: {{model_vista.no_subasta}}
                    </td>
                </tr>
                <tr><td colspan="6">&nbsp;</td></tr>
            </table>

            <table border=1 width="100%">
                <tr>
                    <th class="celda_titulo" width="10%">CÓDIGO BARRAS</th>
                    <th class="celda_titulo" width="20%">NOMBRE</th>
                    <th class="celda_titulo" width="10%">DESCRIPCIÓN</th>
                    <th class="celda_titulo" width="10%">EXISTENCIA</th>
                    <th class="celda_titulo" width="10%">PEDIDO</th>
                    <th class="celda_titulo" width="10%">$ ANTERIOR</th>
                    <th class="celda_titulo" width="10%">$ NUEVO</th>
                    <th class="celda_titulo" width="10%">OBS</th>
                    <template  v-for="(val,index) in columnasProveedores">
                        <th class="celda_titulo">{{ optionsArticulosVistaProveedores.headings[val] }}</th>
                        <th class="celda_titulo" width="10%">OBS</th>
                    </template>
                </tr>

                <tr v-for="(articulo, index) in model_vista.articulos">
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{ articulo.codigo_articulo[0] }}</td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.nombre_articulo}}</td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.descripcion}}</td>
                    <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{ articulo.existencia }}</td>
                    <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >{{articulo.cantidad}}</td>
                    <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                        >${{ formatNumberDec(articulo.precio_compra_con_iva,2) }}</td>
                    <td style="text-align:center;"
                        :class="(articulo.precio_nuevo!=null && articulo.precio_nuevo!=''
                            && parseFloat(formatNumberDec(articulo.precio_nuevo,2))>parseFloat(formatNumberDec(articulo.precio_compra_con_iva,2))?'celda_datos bg-rojo':
                            (articulo.precio_nuevo!=null && articulo.precio_nuevo!=''
                            && parseFloat(formatNumberDec(articulo.precio_nuevo,2))<parseFloat(formatNumberDec(articulo.precio_compra_con_iva,2))?'celda_datos bg-seleccionado':
                            (articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')))">${{formatNumberDec(articulo.precio_nuevo,2)}}
                        </td>
                    <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                    >{{articulo.observaciones}}</td>

                    <template v-for="(val,index) in columnasProveedores">
                        <td style="text-align:center;" :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                            >${{ formatNumberDec(getPrecioProveedor(articulo.id_articulo, articulo.resaltado, val),4) }}
                            </td>
                        <td :class="(articulo.resaltado==1?'celda_datos_resaltado':'celda_datos')"
                            >{{ getObsProveedor(articulo.id_articulo, articulo.resaltado, val) }}</td>

                    </template>

                </tr>
            </table>

            <table width="100%">
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr>
                    <td></td>
                    <td colspan="4" style="font-weight:bolder;">
                        Observaciones: {{model_vista.observaciones}}
                    </td>
                    <td></td>
                </tr>
                <tr><td colspan="6">&nbsp;</td></tr>
                <tr><td colspan="6">&nbsp;</td></tr>
            </table>
        </div>
            
      </v-card-text>
    </v-card>
  </v-dialog>

  <v-dialog v-model="modalCargar" max-width="45%">
    <v-card>
        <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">mdi-file-excel</v-icon>&nbsp;
          <span class="subheading">
            <strong>Cargar Excel de Proveedores</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalCargar = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
            <v-col md="8" lg="8" sm="8">
                <v-autocomplete
                    v-model="excel_proveedor" :items="articulo_proveedores" :hide-no-data="true"
                    item-text="nombre_proveedor" return-object
                    label="Seleccione Proveedor"
                    :rules="[validaProveedorArticulo]"
                    >
                </v-autocomplete>
            </v-col>
            <v-col md="4" lg="4" sm="4">
                <br>
                <v-btn dark color="blue lighten-1" small @click="cargarExcel()"
                    v-show="(excel_proveedor!=null && excel_proveedor!='')">
                    Seleccionar...
                </v-btn>
            </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small  @click.native="modalCargar=false">
          <v-icon>close</v-icon> Cerrar
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

  <v-dialog v-model="modalAsignarProveedor" max-width="65%">
    <v-card>
        <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">supervisor_account</v-icon>&nbsp;
          <span class="subheading">
            <strong>Proveedores Participantes en Subasta</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAsignarProveedor = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row>
            <v-col md="8" lg="8" sm="8">
                <v-autocomplete
                    v-model="proveedor_participante" :items="proveedores_combo" :hide-no-data="true"
                    :loading="loadingProveedores"
                    placeholder="Ingrese nombre de proveedor"
                    prepend-icon="search" clearable
                    item-text="nombre" return-object
                    label="Proveedor">
                </v-autocomplete>
            </v-col>
            <v-col md="4" lg="4" sm="4">
                <br>
                <v-btn dark color="grey" small @click="asignarProveedor()">
                    Agregar
                </v-btn>
            </v-col>
        </v-row>
        <v-client-table  :data="model_vista.proveedores" :columns="columnsProveedores" :options="optionsProveedores" class="elevation-2 pa-2">
            <template slot="actions" slot-scope="props">
                <v-btn x-small fab dark color="pink" @click.native="removerProveedor(props.row.id_proveedor)"
                    v-show="['Subasta Abierta','En Proceso de Cotización', 'Cotización Recibida'].includes(model_vista.estatus)"
                    title="Descartar Proveedor" v-tippy>
                    <v-icon>delete</v-icon>
                </v-btn>
            </template>
        </v-client-table>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn small  @click.native="modalAsignarProveedor=false">
          <v-icon>close</v-icon> Cerrar
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

    <upload-excel-component  title="Cargar Excel" v-show="false"
        :headerRow=4 :firstRow=5 :headersExcel="headersExcel"
        :on-success="handleSuccess" :before-upload="beforeUpload" />

    <HistoricoComponent :props="props_historico"></HistoricoComponent>

</div>
</template>

<script>
import PaginacionComponent from '@/components/PaginacionComponent.vue';
import HistoricoComponent from '@/components/HistoricosComponent.vue';
import Vue from "vue";
import "jsbarcode";
import { jsPDF } from "jspdf";
import 'jspdf-autotable';
import $ from 'jquery';
//import XLSX from 'xlsx';
export default {
  components: {
    "PaginacionComponent": PaginacionComponent,
    "HistoricoComponent": HistoricoComponent,
  },
  watch: {
    model_filters: {
      handler(val) {
        // this.$nextTick(() => {
        //   this.registros.items = [];
        //   this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        // });
      },
      deep: true
    },
    search(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },                    
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        response.data.docs.sort(this.ordenar_nombre);
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },      

      searchProveedor(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"proveedores",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    "sort": ["nombre"],
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.proveedores = response.data.docs;
                    }else
                        this.proveedores = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los proveedores.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },
      searchCategoria(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"categorias",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    "sort": ["nombre"],
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.categorias = response.data.docs;
                    }else
                        this.categorias = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las categorias.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },
  },
  mounted: function() {
    this.model_filters = this.$refs.vuetable.$refs.table.query;
    if (!this.verificaPermiso('t.compras.subastas')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
    //Para detectar el cambio de estatus por lsita y que busque, sin darle enter
    const select = document.getElementsByName('vf__estatus')[0];
    select.addEventListener('change', this.onStatusChanged);
  },
  created: function() {    
    this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
    if(this.sucursal_activa != "0"){ //Si en otra pestaña cambiaron a una sucursal recargamos la pagina
        this.$router.go();            
    }
    self.articulos_encontrados = [];
    this.usuario = this.$local_storage.get("sb_usuario");
    this.email_usuario = this.$local_storage.get("sb_email_usuario");
    this.getUnidadesMedida();
    this.get_sucursales();
    this.getDescripciones();
    this.getArticulos();
    this.getProveedoresCombo();
    this.getCategorias();     
    var today = window.moment().format("YYYY-MM-DD");
    this.fecha_de_venta = window.moment().subtract(3, 'months').format("YYYY-MM-DD");
    this.fecha_a_venta = today;    
  },
  data() {
    return {
        sucursales_tipo:[],
        sucursal_seleccionada:"",
        mensaje_tab_pedido:"",
        active_tab: "busqueda",
        keyTableArticulos:"articulossubasta",
        keyTableArticulosAbierta:"articulossubastaabierta",
        descripciones: [],
        sucursal_activa:"",
        TASA_IVA: 16, //Por default
        suc_matriz:"",
        suc_abastos:"",
        proveedores_combo:[],
        loadingCategorias:false,
        loadingProveedores:false,
        modal_opcion:1,
        loadingCompras: false,
        loadingArticulos: false,
        loadingVentas: false,
        columnsExistencias: ["sucursal","existencia"],
        optionsExistencias: {
            filterable: false,
        },
        articulo_existencias:[],
        items_compras: [],
        items_ventas: [],
        compras: {
            columns: [
            "fecha",
            "existencia",
            "cantidad",
            "precio",
            "proveedor",
            "orden",
            "total"
            ],
            options: {
                filterable: false,
                // sortable: ["fecha", "cantidad", "precio", "total"],
                headings: {
                    fecha: "Fecha",
                    "existencia":"Existían",
                    cantidad: "Cantidad",
                    precio: "Precio Proveedor",
                    "proveedor":"Proveedor",
                    total: "Acciones"
                },
            }
        },
        ventas: {
            columns: [
            "fecha",
            "cantidad",
            "precio",
            ],
            options: {
                filterable: false,
                // sortable: ["fecha", "cantidad", "precio", "total"],
                headings: {
                    fecha: "Fecha",
                    cantidad: "Cantidad",
                    precio: "Precio Venta",
                },
            }
        },

        id_articulo_calculo:"",
        existencia_articulo:"",
        plazo_calculo:"",

        periodo:"1",
        fecha_de_venta: "",
        fecha_a_venta:"",
        fecha_de:"",
        fecha_a: "",
        ultimos_vendidos: 0,
        total_compra: 0,
        promedio_compra: 0,
        precio_promedio_compra: 0,
        total_venta: 0,
        promedio_venta: 0,
        precio_promedio_venta: 0,
        minimo: 0,
        reorden: 0,
        maximo: 0,
        cantidad_pedir: 0,
        consumo_minimo: 0,
        consumo_medio: 0,
        consumo_maximo: 0,
        existencia: 0,

        menu3: false,
        menu4: false,

        precios_articulo:[],
        columnsPrecios: ["tipo_precio","precio_con_impuestos","de","a"],
        optionsPrecios:{
            filterable: false,
            headings:{
                "precio_con_impuestos":"Precio Venta Neto",
            },

        },
        fila_anterior: '',
        proveedores_all:[],
        categorias_all:[],
        modalBusqueda_copy:false,
        g_articulo: "",
        g_cantidad: 0,

        file_adjunto:null,
        modalSugeridos:false,
        model_adjuntos: [], //nombre, _id, _rev
        model_adjuntos_rev: "",
        columnsAdjuntos: ["nombre","actions"],
        optionsAdjuntos: {
            filterable:["nombre"],
            headings:{
                "nombre":"Archivo",
                "actions":"Operaciones"}
        },
          id_proveedor:"",
        columnasProveedores:[],
        articulo_proveedores:[],
        proveedor_participante: null,
        excel_proveedor: null,
        observaciones_editable:"",
        estatus_editable:"",
        modalSugeridos:false,
        props_historico:{modal:false, id_articulo:""},
        headersExcel:[
            "CÓDIGO BARRAS",
            "NOMBRE",
            "DESCRIPCIÓN",
            "PEDIDO",
            "$ NUEVO",
            "OBS"
        ],
        tipo_busqueda: "",
        tipos_busqueda: [
            {"id":"sinproveedor","nombre":"Artículos sin proveedor"},
            {"id":"categoria","nombre":"Categoría"},
            {"id":"articulo","nombre":"Artículo"},
            {"id":"proveedor","nombre":"Proveedor"},],
        tipo_articulo:"sinproveedor",
        tipos_articulos:[
            {"id":"sinproveedor","nombre":"Sin Proveedor"},
            {"id":"conproveedor","nombre":"Con Proveedor"},
            {"id":"ambos","nombre":"Ambos"},
        ],
        isLoading:false,
        sb_sucursal:"",
        nombre_sucursal:"",
        usuario:"",
        email_usuario:"",
        model_vista: {articulos:[]},
        model_filters: "",
        props_paginacion: {
            infiniteHandler: this.infiniteHandler,
            total_registros: 0
        },
        fab: [],
        modalCorreo:false,
        modalEstatus:false,
        modalVistaPrevia:false,
        modalAdjuntarDocumentos: false,
        modalAsignarProveedor:false,
        modalVistaPreviaProveedores: false,
        modalCargar:false,
        modalObservacion:false,
        modalBusqueda:false,
        modalEditable:false,
        modalEditar:false,
        modalArticulos:false,
        modalCantidad:false,
        cantidad_editable:"",
        articulo_editable: {},
        barcode_orden:"",
        row_selected:"",
        articulos_vista:[],
        menu1:false,
        menu2:false,
        name: "datagrid",
        columnsProveedores: ["nombre_proveedor","plazo","contacto","actions"],
        optionsProveedores: {
            filterable:["nombre_proveedor","plazo","contacto"],
            headings:{
                "nombre_proveedor":"Proveedor",
                "plazo":"Días de Plazo",
                "actions":"Operaciones"}
        },
        columns: [
            "no_subasta","fecha","usuario","articulos","estatus","fecha_finalizada", "actions"
        ],
        options: {
            filterable: ["no_subasta","fecha","usuario","articulos","estatus","fecha_finalizada"],
            headings: {
                "no_subasta":"# Subasta",
                "fecha_finalizada":"Fecha Finalizada",
                "actions":"Operaciones"
            },
            listColumns: {
                estatus: [
                    {id: "Subasta Abierta",text: "Subasta Abierta"},
                    {id: "En Proceso de Cotización",text: "En Proceso de Cotización"},
                    {id: "Cotización Recibida",text: "Cotización Recibida"},
                    {id: "Finalizada/Ordenes Creadas",text: "Finalizada/Ordenes Creadas"},
                    {id: "Subasta Cancelada",text: "Subasta Cancelada"},
                ],
            },

        },
        listColumns: {
            estatus: [
                {id: "Subasta Abierta",text: "Subasta Abierta"},
                {id: "En Proceso de Cotización",text: "En Proceso de Cotización"},
                {id: "Cotización Recibida",text: "Cotización Recibida"},
                {id: "Finalizada/Ordenes Creadas",text: "Finalizada/Ordenes Creadas"},
                {id: "Subasta Cancelada",text: "Subasta Cancelada"},
            ],
        },
        breadcrumbs: [{
            text: "Inicio",
            disabled: false,
            to: "/index"
            },
            {
                text: "Compras",
                href: "",
                disabled: true,
            },
            {
                text: "Administrar Subastas",
                href: ""
            }
        ],

        update: false,
        modelEditable:{},
        correo:{
            correos:"",
            responder_a:"",
            asunto:"",
            mensaje:""
        },
        model:{
            _id:"",
            _rev:"",
            type: "subastas",
            no_subasta:"",
            fecha:"",
            proveedores: [],
            estatus: "",
            articulos:[],
            usuario:"",
            observaciones:"",  //Se necesitan al imprimir la orden
            fecha_finalizada:"",
        },
        proveedor: {
                id_proveedor:"",
                nombre_proveedor:"",
                plazo: "",
                contacto:"",
                email:"",
                telefono_1:"",
                condiciones:""
            },
        articulo:{
            id_articulo:"",
            nombre_articulo:"",
            codigo_articulo:"",
            descripcion:"",
            existencia: 0,
            cantidad:"",
            precio_compra:"",  //Último precio de compra
            precio_nuevo:"", //Es el precio del proveedor elegido, aunque se puede editar
            observaciones:"", //Son las observaciones del proveedor elegido aunque se pueden editar
            proveedores: [],
            resaltado: 0, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central
                /*{
                    id_proveedor:"",
                    nombre_proveedor:"",
                    precio_proveedor:"", //Se carga con el excel que contesta el proveedor
                    observaciones_proveedor:"",
                    elegido: 0, //Para saber cual proveedor es el que se eligio para hacerle la compra
                    menor_precio: 0, //Para resaltar con verde el que tenga menor precio
                }],*/
        },

        actual:"",
        cantidad:"",
        actualEditable:"",
        cantidadEditable:"",
        id_articulo:"",
        id_proveedor:"",
        nombre_categoria:"",


        columnsArticulosBakup: ["articulo.nombre", "articulo.descripcion","articulo.categoria",
        "articulo.actual","articulo.precio_compra_con_iva",
        "articulo.ventas_30_dias", "fvm", "fvm2", "actions"],
        /*columnsArticulos: ["articulo.nombre", "articulo.descripcion","articulo.categoria",
        "articulo.minimo","articulo.reorden","articulo.maximo","articulo.actual",
        "articulo.precio_compra_con_iva","articulo.precio_venta","articulo.ventas_30_dias", "articulo.ventas_60_dias", "proveedor.nombre", "actions"],*/
        columnsArticulos: ["articulo.nombre", "articulo.descripcion","articulo.categoria",
        "articulo.actual","articulo.precio_compra_con_iva",
        "articulo.ventas_30_dias", "fvm", "fvm2", "actions"],
            optionsArticulos: {
                filterable: ["articulo.nombre", "articulo.descripcion","articulo.categoria"],
                //filterable: false,
                headings: {
                    "articulo.nombre": "Artículo",
                    "articulo.descripcion":"Unidad","articulo.categoria":"Categoría",
                    "articulo.minimo":"Mínimo","articulo.reorden":"Reorden","articulo.maximo":"Máximo","articulo.actual":"Exist. Matriz",
                    "articulo.precio_compra_con_iva":"Precio Compra",
                    "articulo.precio_venta":"Precio Venta Neto",
                    "articulo.ventas_30_dias":"Ventas 30 días",  
                    "fvm":"FVM",    
                    "fvm2":"FVM %",              
                    "proveedor.nombre": "Proveedor",
                    "actions": "En Subasta Abierta?",
                },
                listColumns: {
                    "articulo.descripcion": []
                },
                rowClassCallback: function (row) {
                    return row.articulo.seleccionado == 1 ? 'bg-seleccionado' : 'bg-default';
                },
                selectable:{
                    mode: 'single', // or 'multiple'
                    only: function(row) {
                    return true // any condition
                    },
                    selectAllMode: 'all', // or 'page',
                    programmatic: false
                }
            },

         data_sugeridos: [],
        columnsArticulosSugeridos: ["articulo.nombre", "articulo.descripcion","articulo.categoria", "minimo", "reorden", "maximo", "actual", "pedir", "actions"],
        optionsArticulosSugeridos: {
            filterable: ["articulo.nombre", "articulo.descripcion","articulo.categoria"],
            //filterable: false,
            headings: {
                "articulo.nombre": "Artículo",
                "articulo.descripcion":"Unidad","articulo.categoria":"Categoría",
                "minimo": "Minimo",
                "reorden": "Reorden",
                "maximo": "Maximo",
                "actual": "Exist. Matriz",
                "pedir": "Por Pedir",
                "actions": "En Subasta Abierta?",
            },
            listColumns: {
                    "articulo.descripcion": []
                },
            rowClassCallback: function (row) {
                return row.articulo.seleccionado == 1 ? 'bg-seleccionado' : 'bg-default'
            },
        },
        columnsArticulosVista: [ "codigo_articulo","nombre_articulo","descripcion","existencia","cantidad",
            "precio_compra_con_iva", "precio_nuevo", "observaciones" ,"actions"],
        optionsArticulosVista: {
            perPage: 10,
            pagination: {
                show: true
            },
            filterable: ["codigo_articulo","nombre_articulo","descripcion", "existencia","cantidad", "precio_compra_con_iva", "precio_nuevo", "observaciones"],
            headings: { "actions":"Opciones", "precio_compra_con_iva":"$ Compra", "descripcion":"Descripción",
            "cantidad":"Pedido","precio_nuevo":"$ Nuevo", "observaciones":"Obs"},
            rowClassCallback: function(row) { return row.resaltado==1?'bg-resaltado':'bg-default'},
            cellClasses:{
                precio_proveedor: [
                    {
                        class:'bg-seleccionado',
                        condition: row => row.precio_proveedor!=null && row.precio_proveedor!='' && row.precio_proveedor!=row.precio_compra_con_iva
                    }
                ]
            }
        },
        columnsArticulosVistaProveedores:[],
        optionsArticulosVistaProveedores:{},

        rules: {
            required: v => !!v || "Este campo es requerido",
        },
        search: "",
        searchProveedor: "",
        searchCategoria: "",
        registros: {
            selected: [],
            items: []
        },
        sucursales:[],
        all_sucursales:[],
        articulos:[],
        proveedores:[],
        categorias:[],
        articulos_encontrados:[],
        unidades_medida:[],
        unidades_medida_filtro:[],
    };
  },
  methods: {
    onStatusChanged(event) {
        this.registros.items = []; 
        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
    },
    goHome() {
        this.$router.replace("/").catch(()=>{});
    },
    
    ordena_especial: function (a, b) {
        // Primero, ordena por sucursal de forma descendente
        if (a.resaltado < b.resaltado) return -1;
        if (a.resaltado > b.resaltado) return 1;

        // Si las sucursales y categorías son iguales, ordena por nombre de forma ascendente
        if (a.nombre_articulo < b.nombre_articulo) return -1;
        if (a.nombre_articulo > b.nombre_articulo) return 1;

        if (a.descripcion < b.descripcion) return -1;
        if (a.descripcion > b.descripcion) return 1;

        // Si son iguales en todos los aspectos, no es necesario cambiar el orden
        return 0;
    },
    
    ordena_articulo: function (a, b) {
        //Ordena categorias ascendente        
        if (a.articulo.categoria[0] < b.articulo.categoria[0]) return -1;
        if (a.articulo.categoria[0] > b.articulo.categoria[0]) return 1;

        // Si categorías son iguales, ordena por nombre de forma ascendente
        if (a.articulo.nombre < b.articulo.nombre) return -1;
        if (a.articulo.nombre > b.articulo.nombre) return 1;

        if (a.descripcion < b.descripcion) return -1;
        if (a.descripcion > b.descripcion) return 1;

        // Si son iguales en todos los aspectos, no es necesario cambiar el orden
        return 0;
    },
    infiniteHandler: function($state) {
      var rows_per_page = 10;
      var page = Math.ceil(this.registros.items.length / rows_per_page); // == 1
      var skip = page * rows_per_page;
      var where = {"type":"subastas","fecha": {"$exists":true}};

      this.options.filterable.forEach(x => {
        if (this.model_filters[x]) {
          where[x] = {
            "$regex": "(?i)" + this.model_filters[x].toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")
          }
        }
      });

      window.axios({
          method: 'POST',
          url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',
          withCredentials: true,
          data: {
            limit: rows_per_page,
            skip: skip,
            selector: where,
            sort: [{"type": "desc"},{"fecha": "desc"}], //Si se pone sort tambien debe estar en el indice para mas rapido
            "use_index": "_design/1a51f6cc8d15253026293ebf49846961c826ed57"
          },
        }).then(response => {
          if (response.data.docs.length) {
            var unique_records = new Set(this.registros.items.map(item => item._id));
            unique_records = Array.from(unique_records.values());
            var nuevos = response.data.docs.filter(e => !unique_records.includes(e._id));
            this.registros.items = this.registros.items.concat(nuevos);            
            this.props_paginacion.total_registros = this.registros.items.length;
            $state.loaded();
          } else {
            $state.complete();
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "0";
        numero = Number(numero);
        if (isNaN(numero)){
            return "0";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    openModalSugeridos: function(type){
        this.modalSugeridos=true;
        this.active_tab = "busqueda";
    },
    getUnidadesMedida: function() {
        this.unidades_medida = [];
        var self = this;
        var url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/unidades?group=true';
        axios.get(url)
        .then(response => {
            if (response.data.rows.length > 0) {
                response.data.rows.forEach(function(e){
                    self.unidades_medida.push(e.key);
                    self.unidades_medida_filtro.push(e.key);
                });
            }
        })
        .catch(error => {
            console.log(error)
        });
    },
    //Le quita el mimetype del inicio
    blobToBase64: function(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                var dataUrl = reader.result;
                var base64 = dataUrl.split(',')[1];
                resolve(base64);
            };
        });
    },

    validaCorreo: function(val){
        if(val != null && val!=""){
            var correos = val.split(",");
            for(var k in correos){
                var correo = correos[k].toString().trim();
                if(!window.funciones.validaCorreo(correo)){
                    return "Hay un correo no valido. Favor de verificar.";
                    break;
                }
            }
        }
        return true;
    },

    enviarCorreo: function(){
        if (this.$refs.formcorreo.validate()) {
            var self = this;

            window.dialogLoader.show('Espere un momento por favor..');

            let datos = {"id_subasta":this.model_vista._id,
                          "fecha": this.parse_date_es(self.model_vista.fecha)};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_subasta/", datos, {            
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                        });

                    self.blobToBase64(blob).then(base64 => {
                        let data = {
                            correos: self.correo.correos,
                            responder_a: self.correo.responder_a,
                            asunto: self.correo.asunto,
                            mensaje: self.correo.mensaje,
                            archivos:[{
                                mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                data: base64,
                                nombre: "SUBASTACORREO"+window.moment(self.model_vista.fecha).format("DDMMYYYY")+".xlsx"
                            }]
                        };
                        window.funciones.enviarEmail(data).then(result =>{
                            window.dialogLoader.showSnackbar('Correo enviado correctamente', {
                                    color: 'success'
                                });
                            self.modalCorreo=false;
                        }).catch( err => {
                            console.log(err);
                            self.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al enviar el correo. Intente nuevamente por favor.",
                                footer: ""
                            });
                        }).then(()=>{
                            window.dialogLoader.hide();
                        })

                    });
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });


        }
    },
    articuloEnOrden: function(id_articulo){
        var seleccionado = 0;
        var abiertas = this.registros.items.filter(function(e){
            return e.estatus == "Subasta Abierta";
        });
        if( abiertas.length>0){
            for(var k in abiertas){
                var mapArt = abiertas[k].articulos.map( a =>{return a.id_articulo;});
                if(mapArt.includes(id_articulo)){
                    seleccionado = 1;
                    break;
                }
            }
        }

        if(seleccionado == 1)
            return true;
        else
            return false;
    },

    consultarSugeridos: function () {
            if (this.id_proveedor != null && this.id_proveedor != "") {
                window.dialogLoader.show('Espere un momento por favor..');

                var self = this;
                var proveedor = self.getProveedor(self.id_proveedor);

                self.limpiartablas();

                var busca = encodeURI(escape(this.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async responseArticulo => {

                    if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){

                            self.data_sugeridos = [];
                            var articulos_ids = [];
                            //  for (var k in responseArticulo.data.docs) {
                            await Promise.all(responseArticulo.data.rows.map(async (val) => {
                                var x = val.value;

                                articulos_ids.push(x._id);

                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                for (var j in x.stock) {
                                    _max += x.stock[j].maximo;
                                    _min += x.stock[j].minimo;
                                    _reorden += x.stock[j].reorden;

                                }

                                    let datos_consulta = {
                                        "id_articulo": x._id,
                                        //Martin solicitó que se muestre la existencia de matriz en la tabla
                                        "id_sucursal": self.suc_matriz._id
                                    };
                                   
                                    await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                        //console.log("Existencias: ", result);
                                        if (result.length > 0) {
                                            result.forEach(el => {
                                                _actual += el.existencia;
                                            });
                                        }
                                        //_actual += result;

                                        var record = {
                                            "articulo": x,
                                            "proveedor": proveedor,
                                            "minimo": _min,
                                            "reorden": _reorden,
                                            "maximo": _max,
                                            "actual": _actual,
                                            "pedir": _max - _actual,

                                        }

                                        if(record.pedir >= record.reorden && record.pedir > 0){
                                            self.data_sugeridos.push(record);                                            
                                        }
                                            

                                    }).catch(err => {
                                        console.log("Ocurrió un error al consultar existencia de artículo.");

                                    });

                                //  _actual = Math.floor(Math.random() * 400) + 1;

                            }));         
                            //self.data_sugeridos.sort(this.ordenar_nombre)

                            /*  let dataS = {
                                    "selector": {
                                        "type": "ventas_stock",
                                        "id_proveedor": this.id_proveedor,
                                        "id_articulo": {
                                            "$in": articulos_ids
                                        },
                                    },
                                } */
                            /*  window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', dataS)
                                    .then(responseStock => {
                                        if (responseStock.data.docs.length > 0) {
                                            self.data_sugeridos.forEach(function (s) {

                                                var filter = responseStock.data.docs.find(e => e.id_articulo == s.articulo._id);

                                                if (filter) {
                                                    s.minimo = filter.min * s.proveedor.plazo;
                                                    s.reorden = Math.ceil((filter.cantidad / 90) * s.proveedor.plazo + s.minimo);
                                                    s.maximo = filter.max * s.proveedor.plazo + s.minimo;
                                                    s.actual = filter.actual;
                                                    s.pedir = s.maximo - s.actual;
                                                }
                                            });
                                            self.data_sugeridos = self.data_sugeridos.filter(e => e.pedir >= e.reorden && e.pedir > 0);

                                        }
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    }).then(() => {
                                        window.dialogLoader.hide();
                                    }); */
                        } else {
                            self.data_sugeridos = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Proveedor sin artículos!",
                                text: "El proveedor no tiene artículos relacionados. Favor de verificar.",
                                footer: ""
                            });
                        }

                    })
                    .catch(error => {
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener los proveedores del artículo.",
                            footer: ""
                        });
                        console.log(error);
                        
                    }).then(() => {
                        window.dialogLoader.hide();
                    });

            }
        },

    consultarArticulo: function () {
        if (this.id_articulo != null && this.id_articulo != "") {
            this.id_proveedor = null;
            this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosBakup));
            this.nombre_categoria = null;
            this.$refs.vuetablearticulos.resetQuery();
            window.dialogLoader.show('Espere un momento por favor..');
            var self = this;

            self.limpiartablas();

            var busca = encodeURI(escape(self.id_articulo));
            window.axios
            //.get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_articulo?key=\"'+busca+'\"')
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+self.id_articulo)
            .then(async response => {

                if(response.data != null){
                    var articulo = response.data;

                    var articulos = [];
                    var proveedores = {};
                    //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                    let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                    let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                    //Ultimos 30 días
                    var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                    var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                    const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                    const [year_a, month_a, day_a] = fecha_a_venta.split("-");

                    //Ultimos 60 días
                    var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                    var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                    const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                    const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

                    //for (var k in rows) {
                    await Promise.all([articulo].map(async (item) => {
                        var proveedor = {};
                        var record = {
                            "articulo": item,
                            "proveedor": proveedor,
                        };
                        record.articulo["ventas_30_dias"] = 0;
                        record.articulo["ventas_60_dias"] = 0;

                        let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                        let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                        await axios.get(url + urlConditions)
                        .then(async response => {

                            if (response.data.rows.length > 0) {
                                response.data.rows.forEach(x => {
                                    record.articulo["ventas_30_dias"] += x.value.cantidad;
                                });
                            }

                            /*await axios
                            .get(url + urlConditions2)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_60_dias"] += x.value.cantidad;
                                    });
                                }
                            })
                            .catch(error => {
                                console.log(error);
                            });*/

                            //Contamos ventas de equivalentes
                            await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                record.articulo["ventas_30_dias"] += conteo["v30"];
                                //record.articulo["ventas_60_dias"] += conteo["v60"];                                
                            });                            

                        })
                        .catch(error => {
                            console.log(error);
                        }).then(async ()=>{
                            let _max = 0;
                            let _min = 0;
                            let _reorden = 0;
                            let _actual = 0;
                            let _actual_total = 0;
                            for (var j in record.articulo.stock) {
                                _max += record.articulo.stock[j].maximo;
                                _min += record.articulo.stock[j].minimo;
                                _reorden += record.articulo.stock[j].reorden;
                            }

                            let datos_consulta = {
                                "id_articulo": record.articulo._id,                                                                        
                            };
                            
                            await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                //console.log("Existencias: ", result);
                                if (result.length > 0) {
                                    result.forEach(el => {
                                        if(el.id_sucursal == self.suc_matriz._id){
                                            _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                        }
                                        _actual_total += el.existencia;
                                    });
                                }
                                //_actual += result;
                                record.articulo['minimo'] = _min;
                                record.articulo['reorden'] = _reorden;
                                record.articulo['maximo'] = _max;
                                record.articulo['actual'] = _actual;
                                record.articulo['actual_total'] = _actual_total;
                                
                            }).catch(err => {
                                console.log("Ocurrió un error al consultar existencia de artículo.");

                            });
                        });

                        articulos.push(record);
                    }));
                    if( articulos.length>0)
                        self.articulos_encontrados = articulos;
                    else{
                        self.articulos_encontrados = [];
                        self.$swal({
                            type: "warning",
                            title: "¡Artículo no relacionado!",
                            text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                            footer: ""
                        });

                    }
                } else {
                    self.articulos_encontrados = [];
                    self.$swal({
                        type: "warning",
                        title: "¡Artículo no relacionado!",
                        text: "El artículo, no está relacionado a ningún proveedor. Favor de verificar.",
                        footer: ""
                    });
                }
            })
            .catch(err => {
                console.log("Error en consultarArticulo: ", err);
            }).then(()=>{
                window.dialogLoader.hide();
            });

        }
    },
    get_conteo_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
        var self = this;
        var conteo = {"v30":0, "v60":0};
        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
        //Ultimos 30 días
        var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
        //Ultimos 60 días
        var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
        var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
        const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
        const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

        return new Promise(async function (resolve, reject) {                
            
            var busca = encodeURI(escape(id_articulo_padre));                
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    //console.log("Buscando equivalencias artículo: ", id_articulo_padre);
                    for(var ke in responseEquivalencias.data.rows){
                        var equivalente = responseEquivalencias.data.rows[ke].value;
                        
                        var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;
                        let urlConditions2 = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                        await axios.get(url + urlConditions)
                        .then(async response => {

                            if (response.data.rows.length > 0) {
                                response.data.rows.forEach(x => {
                                    var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                    conteo["v30"] += cantidad_calculada;                                    
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });    
                        
                        /*await axios.get(url + urlConditions2)
                        .then(async response => {

                            if (response.data.rows.length > 0) {
                                response.data.rows.forEach(x => {
                                    var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                    conteo["v60"] += cantidad_calculada;                                    
                                });
                            }
                            
                        })
                        .catch(error => {
                            console.log(error);
                        });*/

                        await self.get_conteo_ventas_articulo(equivalente['id_articulo_equivalente'], equivalente["cantidad"]).then(function(conteo2){
                            conteo["v30"] += conteo2["v30"];
                            //conteo["v60"] += conteo2["v60"];                            
                        });

                    }
                    return resolve(conteo);
                } else {
                    return resolve(conteo);
                }
            })
            .catch(error => {
                console.log("ERROR al buscar ventas de quivalencia: ", error);   
                return resolve(conteo);  
            });
        });
        
    },
    getProveedoresCombo() {
        this.loadingProveedores = true;
        let data = {
            "selector": {
                "type": "proveedores",
                "estatus":"Activo"
            },
            //"fields":["_id", "nombre"],
            "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.proveedores_combo = [{"_id":"sinproveedor", "nombre":"Sin Proveedor"}];
                response.data.docs.sort(this.ordenar_nombre);
                this.proveedores_combo = this.proveedores_combo.concat(response.data.docs);
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los proveedores.",
                    footer: ""
                });
            }).then(()=>{
                this.loadingProveedores=false;
            });
    },

    getProveedor: function(id_proveedor){
        var filtro = this.proveedores_combo.find(function(e){
            return e._id == id_proveedor;
        });
        if(filtro){
            return filtro;
        } else {
            return false;
        }
    },
    consultarProveedor: function(){
        if(this.id_proveedor != null && this.id_proveedor!=""){
            window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                var busca = encodeURI(escape(this.id_proveedor));
                window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
                .then(async response => {

                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){

                            var rows = response.data.rows;
                            var proveedor = self.getProveedor(self.id_proveedor);

                            self.articulos_encontrados = [];
                            //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                            let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                            //Ultimos 30 días
                            var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                            var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                            const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                            const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                            //Ultimos 60 días
                            var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                            var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                            const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                            const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");


                            for (var k in rows) {
                                if( this.unidades_medida_filtro.includes(rows[k].value.descripcion)){
                                    var record = {
                                        "articulo": rows[k].value,
                                        "proveedor": proveedor,
                                    };
                                    record.articulo["ventas_30_dias"] = 0;
                                    record.articulo["ventas_60_dias"] = 0;
                                    let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                                    let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;
                                    await axios.get(url + urlConditions)
                                    .then(async response => {

                                        if (response.data.rows.length > 0) {
                                            response.data.rows.forEach(x => {
                                                record.articulo["ventas_30_dias"] += x.value.cantidad;
                                            });
                                        }

                                        /*await axios.get(url + urlConditions2)
                                        .then(async response => {

                                            if (response.data.rows.length > 0) {
                                                response.data.rows.forEach(x => {
                                                    record.articulo["ventas_60_dias"] += x.value.cantidad;
                                                });
                                            }
                                        })
                                        .catch(error => {
                                            console.log(error);
                                        });*/

                                        //Contamos ventas de equivalentes
                                        await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                            record.articulo["ventas_30_dias"] += conteo["v30"];
                                            //record.articulo["ventas_60_dias"] += conteo["v60"];                                    
                                        });                            
                                    })
                                    .catch(error => {
                                        console.log(error);
                                    });
                                    self.articulos_encontrados.push(record);
                                }
                            }   
                            self.articulos_encontrados.sort(this.ordena_articulo);

                        }else {
                            self.articulos_encontrados = [];
                            self.$swal({
                                type: "warning",
                                title: "¡Proveedor sin artículos!",
                                text: "El proveedor no tiene artículos relacionados. Favor de verificar.",
                                footer: ""
                            });
                        }

                    })
                    .catch(error => {
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al obtener los proveedores del artículo.",
                            footer: ""
                        });
                        console.log(error);
                    }).then(()=>{
                        window.dialogLoader.hide();  });

        }
    },
    consultarCategoria: function(){
        if(this.nombre_categoria != null && this.nombre_categoria!=""){
            this.id_articulo = null;
            this.id_proveedor = null;
            this.columnsArticulos = this.columnsArticulos.filter( e => e!="articulo.categoria"); //Quitamos columna categoria            
            this.$refs.vuetablearticulos.resetQuery();
            window.dialogLoader.show('Espere un momento por favor..');

            //Buscar articulos con la categoria
            //Buscar en proveedores cada articulo encontrado

            var self = this;

            self.limpiartablas();

            var busca = encodeURI(escape(this.nombre_categoria.toString().trim().toLowerCase()));
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_categoria?key=\"'+busca+'\"')
            .then(async response => {

                var articulos_unicos = [];

                if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var rows = response.data.rows.filter(function(item){
                            return item.value.proveedor.id_proveedor != undefined || ["BULTO","CAJA","FARDO"].includes(item.value.articulo.descripcion.toString().toUpperCase());
                        });

                        var unicos = [];
                        for(var ka in rows){                            
                            if(!articulos_unicos.includes(rows[ka].value.articulo._id)){
                                articulos_unicos.push(rows[ka].value.articulo._id);
                                unicos.push(rows[ka]);
                            }
                        }

                        var articulos = [];
                        var proveedores = {};
                        //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                        let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                        //Ultimos 30 días
                        var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                        const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                        const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                        //Ultimos 60 días
                        var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                        var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                        const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                        const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");

                        //for (var k in rows) {
                        await Promise.all(unicos.map(async (item) => {
                            var proveedor = {};
                            /*if(item.value.proveedor.id_proveedor != undefined){
                                if(proveedores[item.value.proveedor.id_proveedor] != null){
                                    proveedor = proveedores[item.value.proveedor.id_proveedor];
                                } else {
                                    proveedor = self.getProveedor(item.value.proveedor.id_proveedor);
                                    proveedores[item.value.proveedor.id_proveedor] = proveedor;
                                }

                                if(proveedor == false){
                                    proveedor = {"nombre":"(INACTIVO) "+item.value.proveedor.nombre_proveedor, "plazo":0};
                                }
                            } else {
                                proveedor = {};
                            }*/

                            var record = {
                                "articulo": item.value.articulo,
                                "proveedor": proveedor,
                            };
                            record.articulo["ventas_30_dias"] = 0;
                            record.articulo["ventas_60_dias"] = 0;
                            let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                            let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;

                            await axios.get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                /*await axios.get(url + urlConditions2)
                                .then(async response => {

                                    if (response.data.rows.length > 0) {
                                        response.data.rows.forEach(x => {
                                            record.articulo["ventas_60_dias"] += x.value.cantidad;
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });*/

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                    record.articulo["ventas_30_dias"] += conteo["v30"];
                                    //record.articulo["ventas_60_dias"] += conteo["v60"];
                                });                            
                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                let _actual_total = 0;
                                for (var j in record.articulo.stock) {
                                    _max += record.articulo.stock[j].maximo;
                                    _min += record.articulo.stock[j].minimo;
                                    _reorden += record.articulo.stock[j].reorden;
                                }

                                let datos_consulta = {
                                    "id_articulo": record.articulo._id,                                                                        
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            if(el.id_sucursal == self.suc_matriz._id){
                                                _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                            }
                                            _actual_total += el.existencia;
                                        });
                                    }
                                    //_actual += result;
                                    record.articulo['minimo'] = _min;
                                    record.articulo['reorden'] = _reorden;
                                    record.articulo['maximo'] = _max;
                                    record.articulo['actual'] = _actual;
                                    record.articulo['actual_total'] = _actual_total;                              
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });
                            articulos.push(record);
                            articulos_unicos.push(record.articulo._id);
                        }));

                        self.articulos_encontrados = articulos;   
                        self.articulos_encontrados.sort(this.ordena_articulo);                     

                }else{
                    self.articulos_encontrados = [];
                    window.dialogLoader.hide();
                    self.$swal({
                        type: "warning",
                        title: "¡Artículos no encontrados!",
                        text: "No hay artículos en esta categoría. Favor de verificar.",
                        footer: ""
                    });
                }

            })
            .catch(error => {
                window.dialogLoader.hide();
                console.log(error);
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los articulos filtrados.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });

        }
    },
    parse_date(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD");
    },
    parse_date_time(date) {
      if (!date) return null;
      return window.moment(String(date)).format("YYYY-MM-DD HH:mm");
    },
    parse_date_time_mx(date) {
      if (!date) return null;
      return window.moment(String(date)).format("DD-MM-YYYY HH:mm");
    },
    parse_date_es(date) {
      if (!date) return null;
       var fecha = window.moment(String(date)).format('D')+" DE "+ window.moment(String(date)).locale('es').format('MMMM')+" DEL "+window.moment(String(date)).format('YYYY');
       return String(fecha).toUpperCase();
    },


    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },

    getCodigoArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.codigo_barras;
        else
            return ""
    },

    validaActual: function(val){
        if(this.actual != null && this.actual !="" ){
            if(parseFloat(val) > parseFloat(this.actual))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },
    validaActualEditable: function(val){
        if(this.actualEditable != null && this.actualEditable !="" ){
            if(parseFloat(val) > parseFloat(this.actualEditable))
                return "La cantidad es mayor a la actual";
            if(parseFloat(val) <= 0 )
                return "La cantidad debe ser mayor que 0";
        }
        return true;
    },

    elegirProveedorMenorPrecio(){
        window.dialogLoader.show('Espere un momento por favor..');

        //Obtener al proveedor de cada articulo con el menor precio
        var articulos_all = {};

        for(var k in this.articulos_vista){
            var art = this.articulos_vista[k];
            var key_articulo = art.id_articulo.toString() + "-" + art.resaltado.toString();
            articulos_all[key_articulo] = "";
            var menor_precio = 0;
            var id_proveedor_menor_precio = "";
            for(var kp in art.proveedores){
                if(menor_precio != 0){
                    if(parseFloat(art.proveedores[kp].precio_proveedor) > 0 && parseFloat(art.proveedores[kp].precio_proveedor) < menor_precio){
                        menor_precio = parseFloat(art.proveedores[kp].precio_proveedor);
                        id_proveedor_menor_precio = art.proveedores[kp].id_proveedor;
                    }
                } else if(parseFloat(art.proveedores[kp].precio_proveedor) > 0) {
                    menor_precio = parseFloat(art.proveedores[kp].precio_proveedor);
                    id_proveedor_menor_precio = art.proveedores[kp].id_proveedor;
                }
            }
            articulos_all[key_articulo] = id_proveedor_menor_precio;
        }

        for(var k in this.model_vista.articulos){
            var artmodel = this.model_vista.articulos[k];
            var key_articulo = artmodel.id_articulo.toString() + "-" + artmodel.resaltado.toString();
            if(articulos_all[key_articulo] != null){
                for(var kp in artmodel.proveedores){
                    if(artmodel.proveedores[kp].id_proveedor == articulos_all[key_articulo]){
                        artmodel.proveedores[kp].elegido = 1;
                        artmodel.precio_nuevo = parseFloat(artmodel.proveedores[kp].precio_proveedor);
                        artmodel.observaciones = artmodel.proveedores[kp].observaciones_proveedor;
                    } else {
                        artmodel.proveedores[kp].elegido = 0;
                    }
                }
            }
        }

        window.axios
            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE  + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
            .then(response => {
                this.model_vista._rev = response.data.rev;
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
                this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                        footer: ""
                    });
            }).then(() => {
                window.dialogLoader.hide();
            });
    },

    getSumaImpuestos: function(arr_impuestos){
        var sumatoria = 0;
        try {
            if(arr_impuestos && arr_impuestos.length){
                arr_impuestos.forEach(function(e){
                    sumatoria += parseFloat(e.tasa);
                });
            }
        } catch (error) {
            console.log("Error en getSumaImpuestos: ", error);
        }
        return sumatoria;
    },

    elegirProveedor: function(id_articulo, abastos, id_proveedor){
        window.dialogLoader.show('Espere un momento por favor..');
        var self = this;
        for(var k in this.model_vista.articulos){
            var artmodel = this.model_vista.articulos[k];
            if(artmodel.id_articulo == id_articulo && artmodel.resaltado == abastos){
                for(var kp in artmodel.proveedores){
                    if(artmodel.proveedores[kp].id_proveedor == id_proveedor){
                        artmodel.proveedores[kp].elegido = 1;
                        artmodel.precio_nuevo = parseFloat(artmodel.proveedores[kp].precio_proveedor);
                        artmodel.precio_nuevo_sin_iva = parseFloat((artmodel.precio_nuevo / (1 + (self.getSumaImpuestos(artmodel.impuestos_articulo)/100))).toFixed(4))
                        artmodel.observaciones = artmodel.proveedores[kp].observaciones_proveedor;
                    } else {
                        artmodel.proveedores[kp].elegido = 0;
                    }
                }
            }
        }

        window.axios
            .put(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
            .then(response => {
                this.model_vista._rev = response.data.rev;
                this.registros.items = [];
                this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
            })
            .catch(error => {
                this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                        footer: ""
                    });
            }).then(() => {
                window.dialogLoader.hide();
            });
    },

    //Regresa el campo elegido del proveedor
    getElegidoProveedor: function(id_articulo, abastos, id_proveedor){
        var art = this.articulos_vista.find(art => art.id_articulo == id_articulo && art.resaltado == abastos)
        if(art){
            for(var kp in art.proveedores){
                if(art.proveedores[kp].id_proveedor == id_proveedor)
                    return art.proveedores[kp].elegido;
            }
        } else {
            return 0;
        }        
    },

    getPrecioProveedor: function (id_articulo, abastos, id_proveedor){
        var articulo = this.articulos_vista.find(art => art.id_articulo == id_articulo && art.resaltado == abastos);
        if(articulo){
            var pro = articulo.proveedores.find(p => p.id_proveedor == id_proveedor);
            if(pro){
                return pro.precio_proveedor?pro.precio_proveedor:'';
            } else {
                return '';
            }            
        } else 
            return "";
    },
    getObsProveedor: function (id_articulo, abastos, id_proveedor){
        var articulo = this.articulos_vista.find(art => art.id_articulo == id_articulo && art.resaltado == abastos);
        if(articulo){
            for(var kp in articulo.proveedores){
                if(articulo.proveedores[kp].id_proveedor == id_proveedor)
                    return articulo.proveedores[kp].observaciones_proveedor;
            }
        } else {
            return "";
        }
        
    },

    setProveedoresVista: function(row){
        this.articulos_vista = row.articulos;

        this.columnsArticulosVistaProveedores = JSON.parse(JSON.stringify(this.columnsArticulosVista));
        this.optionsArticulosVistaProveedores = {};
        this.optionsArticulosVistaProveedores = this.optionsArticulosVista;        

        this.columnasProveedores = [];

        for(var k in row.proveedores){
            var idpro = row.proveedores[k].id_proveedor;
            var obspro = "observaciones-"+row.proveedores[k].id_proveedor;
            this.columnasProveedores.push(idpro);
            this.columnsArticulosVistaProveedores.push(idpro);
            this.columnsArticulosVistaProveedores.push(obspro);
            this.optionsArticulosVistaProveedores.headings[idpro] = row.proveedores[k].nombre_proveedor;
            this.optionsArticulosVistaProveedores.headings[obspro] = "Observaciones";
        }
        
    },

    verArticulos: function(row){
        window.dialogLoader.show('Espere un momento por favor..');
        var self=this;
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            this.setProveedoresVista(row);            
            this.$nextTick(() => {
                self.modalArticulos=true;
                self.keyTableArticulos = moment(new Date()).format("x");            
            } ); 
            
            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });
    },

    validaCantidadFloatRequerido: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseFloat(value)<=0)
          return "Debe ser mayor que cero";
        else if (parseFloat(value) >= 1000000 )
            return "Cantidad demasiado grande";
      } else if(value != null && value == "")
            return "Campo requerido";
      return true;
    },

    validaCantidadFloat: function(value) {
      const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
      if (value != null && value != "" && !pattern.test(value)) {
        return "Cantidad no válida"
      } else if (value != null && value != ""){
        if(parseFloat(value)<=0)
          return "Debe ser mayor que cero";
      }
      return true;
    },

    openModal: function(type) {
      if (type === 1) {
        this.update = false;

      } else {
        this.update = true;
      }
      this.modal_opcion = 1; //1-Articulos, 2-Sugeridos
      this.modalBusqueda = true;
      this.active_tab = "busqueda";
      /*setTimeout(() => {
            this.$nextTick(() => this.$refs.selectbusqueda.focus());
        }, 100);*/
    },

    openModalSugeridos: function(type){
        this.id_proveedor = null;
        this.modal_opcion = 2; //1-Articulos,  2-Sugeridos
        this.modalBusqueda = true;
    },

    valida_fecha: function(fecha) {
        if (fecha == "Invalid date")
            return "Fecha no válida."
        else
            return true;
    },

    editArticulo: function(articulo, abastos){
        this.articulo_editable = articulo;
        this.modalEditable = true;
    },
    
    saveNuevaCantidadArticulo: function(){
        if (this.$refs.formeditable.validate()) {

            try {

                var descartado = this.model_vista.articulos.filter( e => {
                    return e.id_articulo == this.articulo_editable.id_articulo && e.resaltado == this.articulo_editable.resaltado;
                });
                if(descartado.length>0){
                    window.dialogLoader.show('Espere un momento por favor..');

                    //Editar articulo del modelo con los cambios
                    for(var key in this.model_vista.articulos){
                        if(this.model_vista.articulos[key].id_articulo == this.articulo_editable.id_articulo && this.model_vista.articulos[key].resaltado == this.articulo_editable.resaltado){
                            this.model_vista.articulos[key].cantidad = parseFloat(this.articulo_editable.cantidad);
                            this.model_vista.articulos[key].precio_compra = parseFloat(this.articulo_editable.precio_compra);
                            this.model_vista.articulos[key].observaciones = this.articulo_editable.observaciones;
                        }
                    }

                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                        .then(response => {
                            this.model_vista._rev = response.data.rev;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        })
                        .catch(error => {
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                    footer: ""
                                });
                        }).then(() => {
                            this.modalEditable = false;
                            window.dialogLoader.hide();
                        });

                }

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }

        }
    },

    resetSeleccionado: function(){
        //console.log("FILA: ", this.fila_anterior);

        for(var k in this.datafiltered){
            //console.log(this.datafiltered[k].articulo.nombre);
            var fila = "celda_"+this.datafiltered[k].articulo._id;
            var anterior = document.getElementsByClassName(fila);
            for(var i = 0; i < anterior.length; i++) {
                anterior[i].style.backgroundColor = 'transparent';
                anterior[i].style.fontWeight = 'normal';
            }
        }

        /*var actual = document.getElementsByClassName(this.fila_anterior);
        for(var i = 0; i < actual.length; i++) {
            actual[i].style.backgroundColor = '#d6dbdf'; //Gris
            actual[i].style.fontWeight = 'bold';
        }*/
    },

    calcular_compras_ventas: function(){
        this.get_existencia();
        this.consultar_compras();
        this.calcular();
    },

    limpiartablas: function(){
        var anterior = document.getElementsByClassName(this.fila_anterior);
        for(var i = 0; i < anterior.length; i++) {
            anterior[i].style.backgroundColor = 'transparent';
            anterior[i].style.fontWeight = 'normal';
        }
        this.precios_articulo = [];
        this.articulo_existencias = [];
        this.items_compras = [];
        this.items_ventas = [];
    },

    mostrarInformacion: function(row){
        //console.log(row);

        this.id_articulo_calculo = row.articulo._id;
        this.row_selected = row;

        this.plazo_calculo = 1;
        
        //TODO: Calcular compras
        this.get_existencia();
        this.consultar_compras();
        this.calcular(); //calcular ventas

        this.precios_articulo = row.articulo.precios_venta;

        var clave_fila = "celda_"+row.articulo._id;

        var anterior = document.getElementsByClassName(this.fila_anterior);
        for(var i = 0; i < anterior.length; i++) {
            anterior[i].style.backgroundColor = 'transparent';
            anterior[i].style.fontWeight = 'normal';
        }

        this.resetSeleccionado();

        var actual = document.getElementsByClassName(clave_fila);
        for(var i = 0; i < actual.length; i++) {
            actual[i].style.backgroundColor = '#d6dbdf'; //Gris
            actual[i].style.fontWeight = 'bold';
        }

        this.fila_anterior = clave_fila;
    },

    ingresarCantidad: function(row){
        this.row_selected = row;
        this.cantidad_editable = "";
        this.sucursal_seleccionada = this.suc_matriz;
        this.modalCantidad = true;
        
        setTimeout(() => {
            this.$nextTick(() => this.$refs.ingresacantidad.focus());
        }, 100);
    },

    showInfo: function(id_articulo){
        this.props_historico.id_articulo = id_articulo;
        this.props_historico.plazo = null;
        this.props_historico.modal = true;
    },

     ver_subasta: function(){
        try {

            window.dialogLoader.show('Espere un momento por favor..');            
            var self = this;
            this.mensaje_tab_pedido = "";
            var busca = encodeURI(escape(this.usuario.toString().toLowerCase()));
            window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/subastas/_view/subastas_abiertas?key=\"'+busca+'\"')
                .then(async response => {
                    //console.log(response);

                    var orden_abierta = [];
                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){

                        if (response.data.rows.length>1){
                            this.mensaje_tab_pedido = "El usuario "+this.usuario+" tiene mas de 1 subasta abierta. Se muestra la más reciente.";
                        }
                        var ordenado = response.data.rows.reverse();
                        var subasta_abierta = ordenado[0].value;

                         this.model_vista = subasta_abierta;
                        //console.log(row.articulos);
                        //this.setProveedoresVista(subasta_abierta);
                        this.articulos_vista = subasta_abierta.articulos;
                        this.columnsArticulosVistaProveedores = JSON.parse(JSON.stringify(this.columnsArticulosVista));
                        this.optionsArticulosVistaProveedores = {};
                        this.optionsArticulosVistaProveedores = this.optionsArticulosVista;                        

                        var self=this;                        
                        this.$nextTick(() => self.keyTableArticulosAbierta = moment(new Date()).format("x") ); //Refrescar tabla

                    } else {
                        this.$swal({
                            type: "info",
                            title: "¡No Hay Subasta Abierta!",
                            text: "El usuario "+this.usuario+" no tiene una subasta en estatus Abierta. Favor de Verificar.",
                            footer: ""
                        });                                               
                    }
                }).catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al buscar la subasta. Intente nuevamente.",
                        footer: ""
                    });                    
                }).then(()=>{
                    window.dialogLoader.hide();
                });
        } catch(error){
            window.dialogLoader.hide();
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al buscar la subasta. Intente Nuevamente.",
                footer: ""
            });            
            console.log(error);
        }
     },

    agregarArticulo: async function(){
        try {

            window.dialogLoader.show('Espere un momento por favor..');

            var articulo = this.row_selected.articulo;
            var cantidad = parseFloat(this.cantidad_editable);

            var suc_seleccionada = this.sucursal_seleccionada
            var articulo_abastos = 0;
            if(this.sucursal_seleccionada && this.sucursal_seleccionada.abastos == 1)
                articulo_abastos = 1;

            var self = this;

            var busca = encodeURI(escape(this.usuario.toString().toLowerCase()));
            await window.axios
                .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/subastas/_view/subastas_abiertas?key=\"'+busca+'\"')
                .then(async response => {
                    //console.log(response);

                    var orden_abierta = [];
                    if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var ordenado = response.data.rows.reverse();

                        //Si ya existe la subasta se agrega el articulo (si ya existe articulo aumentar cantidad)
                            var articuloEncontrado = ordenado[0].value;
                            var articuloExiste = 0;
                            for(var key in articuloEncontrado.articulos){
                                if(articuloEncontrado.articulos[key].id_articulo == articulo._id && articuloEncontrado.articulos[key].resaltado == articulo_abastos){
                                    articuloExiste = 1;
                                    articuloEncontrado.articulos[key].cantidad = cantidad;
                                }
                            }

                            if (articuloExiste == 0){ //Si no lo encontró, se agrega

                                await window.funciones_lotes.consultaExistenciaTotal({
                                    "id_articulo": articulo._id
                                })
                                .then(async responseExistencia => {
                                    var model = {
                                        id_articulo: articulo._id,
                                        nombre_articulo: articulo.nombre,
                                        codigo_articulo: articulo.codigo_barras,
                                        descripcion: articulo.descripcion,
                                        impuestos_articulo: articulo.impuestos,
                                        existencia: responseExistencia,
                                        cantidad: cantidad,
                                        precio_compra: articulo.precio_compra,  //Último precio de compra
                                        precio_compra_con_iva: articulo.precio_compra_con_iva,  //Último precio de compra
                                        precio_nuevo:"", //Es el precio del proveedor elegido, aunque se puede editar
                                        precio_nuevo_sin_iva:"",
                                        observaciones:"", //Son las observaciones del proveedor elegido aunque se pueden editar
                                        proveedores: [],
                                        resaltado: articulo_abastos, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central
                                    };
                                    articuloEncontrado.articulos.push(model);
                                    articuloEncontrado.articulos.sort(self.ordena_especial);

                                    await window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                        .then(response => {
                                            window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                                color: 'success'
                                            });
                                            self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        })
                                        .catch(error => {
                                            self.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al agregar artículo a la subasta. Intente nuevamente.",
                                                footer: error
                                            });
                                        }).then(() => {
                                            window.dialogLoader.hide();
                                        });
                                }).catch(err => {
                                    window.dialogLoader.hide();
                                    console.log("Error ", err);
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                        footer: ""
                                    });
                                });


                            } else {
                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                    .then(response => {
                                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                            color: 'success'
                                        });
                                        self.registros.items = [];
                                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    })
                                    .catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                            footer: error
                                        });
                                    }).then(() => {
                                        window.dialogLoader.hide();
                                    });
                            }



                    } else {
                        //Si no existe la subasta se crea una nueva con el articulo
                        //Obtener consecutivo de Subastas
                        //Obtener ultimo precio de compra

                        let dataConsecutivo={
                            "id_sucursal": "", //requerido
                            "tabla":"subastas", //requerido
                            "longitud":5  //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                            };
                        await window.funciones.getConsecutivo(dataConsecutivo)
                        .then(async resultConsecutivo =>{

                            var consecutivo = resultConsecutivo;
                            var anio = window.moment().format("YYYY").toString().substr(-2);
                            var orden = "S"+ anio + consecutivo.toString();

                            await window.funciones_lotes.consultaExistenciaTotal({
                                "id_articulo": articulo._id
                            })
                            .then(async responseExistencia => {
                                var model = {
                                    type:"subastas",
                                    no_subasta: orden,
                                    fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                    proveedores: [],
                                    estatus: "Subasta Abierta",
                                    articulos:[
                                        {
                                            id_articulo: articulo._id,
                                            nombre_articulo: articulo.nombre,
                                            codigo_articulo: articulo.codigo_barras,
                                            descripcion: articulo.descripcion,
                                            impuestos_articulo: articulo.impuestos,
                                            cantidad: cantidad,
                                            precio_compra: parseFloat(articulo.precio_compra),  //Último precio de compra
                                            precio_compra_con_iva: parseFloat(articulo.precio_compra_con_iva),
                                            existencia: responseExistencia,
                                            precio_nuevo: "",
                                            precio_nuevo_sin_iva: "",
                                            observaciones: "",
                                            proveedores: [],
                                            resaltado: articulo_abastos
                                        }
                                    ],
                                    usuario: self.usuario,
                                    observaciones:"",
                                    fecha_finalizada:"",
                                };


                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                                    .then(response => {
                                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                            color: 'success'
                                        });
                                        self.registros.items = [];
                                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    })
                                    .catch(error => {
                                        this.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al agregar artículo a la subasta. Intente nuevamente.",
                                            footer: error
                                        });
                                    }).then(() => {
                                        window.dialogLoader.hide();
                                    });
                            }).catch(err => {
                                window.dialogLoader.hide();
                                console.log("Error ", err);
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                    footer: ""
                                });
                            });



                        }).catch( err => {
                            console.log(err);
                            self.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                footer: ""
                            });
                        });

                    }
                }).catch(error => {
                    window.dialogLoader.hide();
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al verificar subasta. Intente nuevamente.",
                        footer: error
                    });
                }).then( ()=>{
                    this.modalCantidad = false;
                });

        } catch (error) {
            window.dialogLoader.hide();
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al iniciar la subasta. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
        }

    },


     agregarSugerido: function (row) {
            this.row_selected = row;
            this.cantidad_editable = row.pedir;
            this.agregarArticulo();
        },

        validarArticulo: function () {
            if (this.$refs.formcantidad.validate()) {
                this.agregarArticulo();
            }
        },

        agregarTodosSugerido: async function () {
            try {
                let self = this;
                window.dialogLoader.show('Espere un momento por favor..');

                var id_proveedor = self.id_proveedor;

                //console.log("paso 1");

                var busca = encodeURI(escape(this.usuario.toString().toLowerCase()));
                await window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/subastas/_view/subastas_abiertas?key=\"'+busca+'\"')
                    .then(async response => {
                        //console.log("paso 2");

                        if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                        var ordenado = response.data.rows.reverse();

                        //Si ya existe la subasta se agrega el articulo (si ya existe articulo aumentar cantidad)
                            var articuloEncontrado = ordenado[0].value;

                             await Promise.all(self.data_sugeridos.map(async (x) => {
                         //  await self.data_sugeridos.forEach( async function (x) {
                                    //console.log("paso 3");

                                    var articuloExiste = 0;
                                    for (var key in articuloEncontrado.articulos) {
                                        if (articuloEncontrado.articulos[key].id_articulo == x.articulo._id) {
                                            articuloExiste = 1;
                                            articuloEncontrado.articulos[key].cantidad = parseFloat(x.pedir);
                                        }
                                    }
                                    if (articuloExiste == 0) { //Si no lo encontró, se agrega


                                        await window.funciones_lotes.consultaExistenciaTotal({
                                            "id_articulo": x.articulo._id
                                        })
                                        .then(async responseExistencia => {
                                            var model = {
                                                id_articulo: x.articulo._id,
                                                nombre_articulo: x.articulo.nombre,
                                                codigo_articulo: x.articulo.codigo_barras,
                                                descripcion: x.articulo.descripcion,
                                                existencia: responseExistencia,
                                                cantidad: parseFloat(x.pedir),
                                                precio_compra: x.articulo.precio_compra, //Último precio de compra
                                                precio_compra_con_iva: x.articulo.precio_compra_con_iva,
                                                precio_nuevo:"", //Es el precio del proveedor elegido, aunque se puede editar
                                                precio_nuevo_sin_iva:"",
                                                observaciones:"", //Son las observaciones del proveedor elegido aunque se pueden editar
                                                proveedores: [],
                                                resaltado: 0, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central
                                            };
                                            articuloEncontrado.articulos.push(model);
                                            //console.log("paso 4");
                                        }).catch(err => {
                                            window.dialogLoader.hide();
                                            console.log("Error ", err);
                                            self.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                                footer: ""
                                            });
                                        });


                                    }

                                }));


                                //console.log("paso 5");
                                //console.log(articuloEncontrado);

                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', articuloEncontrado)
                                    .then(response => {
                                        window.dialogLoader.showSnackbar('Artículo agregado correctamente', {
                                            color: 'success'
                                        });
                                        self.registros.items = [];
                                        self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                        self.modalSugeridos = false;
                                        //console.log("paso 6");
                                        window.dialogLoader.hide();
                                    })
                                    .catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al agregar artículo a la subasta. Intente nuevamente.",
                                            footer: error
                                        });
                                    });

                        } else {
                            //Si no existe la subasta se crea una nueva con el articulo
                            //Obtener consecutivo de Subastas
                            //Obtener ultimo precio de compra

                            let dataConsecutivo = {
                                "id_sucursal": "", //requerido
                                "tabla": "subastas", //requerido
                                "longitud": 5 //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                            };
                            await window.funciones.getConsecutivo(dataConsecutivo)
                                .then(async resultConsecutivo => {

                                    var consecutivo = resultConsecutivo;
                                    var anio = window.moment().format("YYYY").toString().substr(-2);
                                    var orden = "S"+ anio + consecutivo.toString();

                                    var model = {
                                        type:"subastas",
                                        no_subasta: orden,
                                        fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                        proveedores: [],
                                        estatus: "Subasta Abierta",
                                        articulos: [],
                                        usuario: self.usuario,
                                        observaciones:"",
                                        fecha_finalizada:"",
                                    };

                                   await Promise.all(self.data_sugeridos.map(async (x) => {
                                  //  self.data_sugeridos.forEach(async function (x) {


                                        await window.funciones_lotes.consultaExistenciaTotal({
                                                "id_articulo": x.articulo._id
                                            })
                                            .then(async responseExistencia => {
                                                var reg = {
                                                    id_articulo: x.articulo._id,
                                                    nombre_articulo: x.articulo.nombre,
                                                    codigo_articulo: x.articulo.codigo_barras,
                                                    descripcion: x.articulo.descripcion,
                                                    cantidad: parseFloat(x.pedir),
                                                    precio_compra: x.articulo.precio_compra, //Último precio de compra
                                                    precio_compra_con_iva: x.articulo.precio_compra_con_iva,
                                                    existencia: responseExistencia,
                                                    precio_nuevo: "",
                                                    precio_nuevo_sin_iva: "",
                                                    observaciones: "",
                                                    proveedores: [],
                                                    resaltado: 0, // 0, 1  Si está resaltado cambiarlo a color amarillo, para indicar que esos van a Abastos, los demas a central
                                                }
                                                model.articulos.push(reg);
                                            //console.log("paso 3");
                                            //console.log(x);
                                            }).catch(err => {
                                                window.dialogLoader.hide();
                                                console.log("Error ", err);
                                                self.$swal({
                                                    type: "error",
                                                    title: "¡Operación no Permitida!",
                                                    text: "Ocurrió un error al obtener la existencia del articulo. Intente nuevamente.",
                                                    footer: ""
                                                });
                                            });

                                    }));

                                    await window.axios
                                        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                                        .then(response => {
                                            window.dialogLoader.showSnackbar('Orden Creada Correctamente', {
                                                color: 'success'
                                            });
                                            self.registros.items = [];
                                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                            self.modalSugeridos = false;
                                            //console.log("paso 6");
                                        })
                                        .catch(error => {
                                            this.$swal({
                                                type: "error",
                                                title: "¡Operación no Permitida!",
                                                text: "Ocurrió un error al agregar artículo a la orden. Intente nuevamente.",
                                                footer: error
                                            });
                                        }).then(()=>{
                                            window.dialogLoader.hide();
                                        });
                                }).catch(err => {
                                    window.dialogLoader.hide();
                                    console.log(err);
                                    self.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                        footer: ""
                                    });
                                });

                        }
                    })
                    .catch(error => {
                        window.dialogLoader.hide();
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al verificar subasta. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        self.modalCantidad = false;
                    });

            } catch (error) {
                window.dialogLoader.hide();
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al iniciar la subasta. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
            }

        },

    deleteArticulo: function(idArticulo, abastos){

        var descartado = this.model_vista.articulos.filter( e => {
            return e.id_articulo == idArticulo && e.resaltado==abastos;
        });
        if(descartado.length>0){
            window.dialogLoader.show('Espere un momento por favor..');

            //Eliminar articulo del modelo
            var filter = this.model_vista.articulos.filter( e => {
                return e.id_articulo != idArticulo || (e.id_articulo != idArticulo && e.resaltado!=abastos) ;                
            });
            this.model_vista.articulos = filter;
            if(this.model_vista.articulos.length == 0){//Si ya no tiene articulos se elimina
                window.axios
                    .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE  + "/" + this.model_vista._id + "?rev=" + this.model_vista._rev)
                    .then(response => {

                    })
                    .catch(error => {
                        this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                            footer: error
                        });
                    }).then(() => {
                        this.modalArticulos = false;
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });
            } else {//Si no se elimina se guarda
                window.axios
                    .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                    .then(response => {
                        this.model_vista._rev = response.data.rev;
                    })
                    .catch(error => {
                        this.$swal({
                                type: "error",
                                title: "¡Operación no Permitida!",
                                text: "Ocurrió un error al eliminar el artículo. Intente nuevamente.",
                                footer: error
                            });
                    }).then(() => {
                        this.registros.items = [];
                        this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        window.dialogLoader.hide();
                    });
            }

        }
    },

    editarObservaciones: function(row){
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model = row;
            this.modalObservacion = true;            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });        
    },

    guardarObservaciones: function(){
        if (this.$refs.formobservacion.validate()) {
            window.dialogLoader.show('Espere un momento por favor..');

            this.model.observaciones = this.observaciones_editable;
            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', this.model)
                .then(response => {
                    window.dialogLoader.showSnackbar('Las observaciones se guardaron correctamente', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar observaciones. Favor de intentar nuevamente.",
                            footer: error
                        });
                }).then(() => {
                    this.modalObservacion = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });
        }
    },

    eliminarOrden: function(row){
        let data = {
            selector: {
                type:"pedidos",
                no_subasta: row.no_subasta
            },
            use_index: "_design/060eba6482245aa90249dd488f5f9aab"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {

                if(response.data != null && response.data.docs!=null && response.data.docs.length>0){
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "No se puede eliminar la subasta, porque tiene ordenes de compra relacionadas.",
                        footer: ""
                    });
                } else {
                    this.$swal({
                        type: "info",
                        title: "Cuidado",
                        text: "¿Realmente desea eliminar la subasta?",
                        footer: "",
                        showCancelButton: true,
                        cancelButtonColor: "#d33",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "Si",
                        cancelButtonText: "No"
                    }).then(resultado => {
                        if (resultado.value) {

                            window.dialogLoader.show('Espere un momento por favor..');

                            //TODO: Buscar si tiene ordenes de compra creadas, si si entonces mostrar mensaje y no se puede borrar
                            //en caso contrario se elimina la subasta

                            window.axios
                                .delete(process.env.VUE_APP_COUCHDB_URL+ '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id + "?rev=" + row._rev)
                                .then(response => {
                                    window.dialogLoader.showSnackbar('La subasta se eliminó correctamente..', {
                                        color: 'success'
                                    });
                                })
                                .catch(error => {
                                    this.$swal({
                                        type: "error",
                                        title: "¡Operación no Permitida!",
                                        text: "Ocurrió un error al eliminar la subasta. Intente nuevamente.",
                                        footer: error
                                    });
                                }).then(() => {
                                    this.modalBusqueda = false;
                                    this.registros.items = [];
                                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                    window.dialogLoader.hide();
                                });

                        }
                    });
                }

            }).catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al intentar eliminar el registro. Favor de intentar nuevamente.",
                    footer: error
                });
            });



    },
    cambiarEstatus(row){
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            this.estatus_editable = row.estatus;
            this.modalEstatus = true;
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });        
    },

    changeEstatus(){
        try {
            window.dialogLoader.show('Espere un momento por favor..');
            this.model_vista.estatus = this.estatus_editable;
            if(this.model_vista.estatus == "Finalizada/Ordenes Creadas")
                this.model_vista.fecha_finalizada = window.moment().format("YYYY-MM-DDTHH:mm:ss");
            
            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                .then(response => {
                    this.model_vista._rev = response.data.rev;
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al editar el estatus. Intente nuevamente.",
                            footer: ""
                        });
                }).then(() => {
                    this.modalEstatus = false;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                    window.dialogLoader.hide();
                });

        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al editar el registro. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
            window.dialogLoader.hide();
        }

    },
    showAsignarProveedor(row){
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            this.modalAsignarProveedor = true;
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });           
    },
    asignarProveedor(){
        //console.log(this.proveedor_participante);

        if (this.proveedor_participante != null) {

            try {
                var proveedor = this.proveedor_participante;
                var encontrado = this.model_vista.proveedores.filter( e => {
                    return e.id_proveedor == proveedor._id;
                });
                if(encontrado.length == 0){
                    window.dialogLoader.show('Espere un momento por favor..');

                    var addProveedor = {
                        id_proveedor:proveedor._id,
                        nombre_proveedor:proveedor.nombre,
                        plazo: proveedor.plazo,
                        contacto: proveedor.contacto,
                        email: proveedor.email,
                        condiciones: proveedor.condiciones,
                        telefono_1: proveedor.telefono_1
                    };
                    this.model_vista.proveedores.push(addProveedor);

                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                        .then(response => {
                            this.model_vista._rev = response.data.rev;
                            this.registros.items = [];
                            this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        })
                        .catch(error => {
                            this.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al guardar al proveedor. Intente nuevamente.",
                                    footer: ""
                                });
                        }).then(() => {
                            this.proveedor_participante = null;
                            window.dialogLoader.hide();
                        });
                } else
                    this.proveedor_participante = null;

            } catch (error) {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al agregar al proveedor. Intente nuevamente.",
                    footer: ""
                });
                console.log(error);
                window.dialogLoader.hide();
            }
        }
    },
    removerProveedor(id_proveedor){
        try {
            var proveedor = this.proveedor_participante;
            var encontrado = this.model_vista.proveedores.filter( e => {
                return e.id_proveedor != id_proveedor;
            });

            window.dialogLoader.show('Espere un momento por favor..');

            this.model_vista.proveedores = encontrado;

            window.axios
                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_vista._id + '?conflicts=true', this.model_vista)
                .then(response => {
                    this.model_vista._rev = response.data.rev;
                    this.registros.items = [];
                    this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                })
                .catch(error => {
                    this.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al guardar. Intente nuevamente.",
                            footer: ""
                        });
                }).then(() => {
                    this.proveedor_participante = null;
                    window.dialogLoader.hide();
                });

        } catch (error) {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al remover al proveedor. Intente nuevamente.",
                footer: ""
            });
            console.log(error);
            window.dialogLoader.hide();
        }
    },
    vistaPreviaExcel(row){
        window.dialogLoader.show('Espere un momento por favor..');
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            var correosProveedores = this.model_vista.proveedores.map(function(e){                
                    return e.email;                
            });
            this.correo.correos = "";
            correosProveedores = correosProveedores.filter(e => e!=undefined && e!="");            
            if(correosProveedores.length>0){
                this.correo.correos = correosProveedores.join(", ");
            }
            this.correo.responder_a = this.email_usuario;
            this.correo.asunto = "Subasta - La Bodeguita";
            this.correo.mensaje = "Solicitud de Cotización de Subasta";
            this.modalVistaPrevia = true;
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });                   
    },

    vistaPreviaProveedoresExcel(row){
        window.dialogLoader.show('Espere un momento por favor..');
        var self=this;
        window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
        .then(response => {
            row = response.data;
            this.model_vista = row;
            this.setProveedoresVista(row);            
            this.$nextTick(() => {
                self.articulo_proveedores = row.proveedores;
                self.modalVistaPreviaProveedores = true;
            } ); 
            
            
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                footer: ""
            });
        }).then(()=>{
            window.dialogLoader.hide();
        });   
        
        
        

    },

    //Buscar si el proveedor ya está en los articulos, para mostrar advertencia
    validaProveedorArticulo: function(val){
        var self = this;
        if(val != null && val!=""){
            for(var k in self.model_vista.articulos){
                for(var kp in self.model_vista.articulos[k].proveedores){
                    if(val.id_proveedor == self.model_vista.articulos[k].proveedores[kp].id_proveedor)
                        return "La cotización del proveedor ya se cargó anteriormente. Si continua se sobreescribirán sus precios.";
                }
            }
        }
        return true;
    },
    showCargarExcel(){
        this.excel_proveedor = null;
        this.modalCargar = true;
    },
    cargarExcel(){
        var el = document.getElementById("excel-upload-input");
        el.click();
    },

    descargaExcel(){

        //var wb = XLSX.utils.table_to_book(document.getElementById('orden_excel'));
        //XLSX.writeFile(wb, "OrdenCompra.xlsx");
        var self = this;

        if(this.modalVistaPreviaProveedores == true){ //Excel con proveedores, uso interno
            window.dialogLoader.show('Espere un momento por favor..');
            let datos = {"id_subasta":this.model_vista._id,
                          "fecha": this.parse_date_es(self.model_vista.fecha)};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_subasta_proveedores/", datos, {                       
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                        }),
                        url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "SUBASTA "+this.parse_date_es(self.model_vista.fecha)+".xlsx";
                    a.click();
                    a.parentNode.removeChild(a);
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();
                });
        } else { //Excel de subasta, es el que se envía a los proveedores
            window.dialogLoader.show('Espere un momento por favor..');
            let datos = {"id_subasta":this.model_vista._id,
                          "fecha": this.parse_date_es(self.model_vista.fecha)};
            window.axios
            .post(process.env.VUE_APP_REPORTES_URL + "/reportes/excel_subasta/", datos, {            
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                })
                .then(response => {
                    var bytes = response.data;
                    let blob = new Blob([bytes], {
                        type: response.headers["content-type"]
                        }),
                        url = window.URL.createObjectURL(blob);

                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = url;
                    a.download = "SUBASTACORREO"+window.moment(self.model_vista.fecha).format("DDMMYYYY")+".xlsx";
                    a.click();
                    a.parentNode.removeChild(a);
                })
                .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el excel. Intente nuevamente.",
                        footer: ""
                    });
                }).then(() => {
                    window.dialogLoader.hide();
                });
        }


        // FORMA ANTERIOR
        /*var tabla = document.getElementById("orden_excel");

        var nombreArchivo = "SUBASTACORREO"+window.moment(self.model_vista.fecha).format("DDMMYYYY");
        if(this.modalVistaPreviaProveedores == true){
            tabla = document.getElementById("orden_excel_proveedores");
            nombreArchivo = "SUBASTA "+this.parse_date_es(self.model_vista.fecha);
        }
        var options = {
            preserveColors: true,
            exclude: ".noExcel",
            name:"Orden_"+self.model_vista.no_orden,
            sheetName:self.model_vista.no_orden,
            filename: nombreArchivo,//do not include extension
            fileext:".xls", // file extension
            download: 1, //1 - Para que descargue el archivo, 0 - para que regrese el blob
        }
        self.exportExcel(tabla, options);*/

    },
    exportExcel: function ( element, options ) {
        var settings = options;

        //var utf8Heading = "<meta http-equiv=\"content-type\" content=\"application/vnd.ms-excel; charset=UTF-8\" >";
        var utf8Heading = "<meta http-equiv=\"Content-type\" content=\"text/html;charset=utf-8\" >";
        //var utf8Heading = "<meta http-equiv=\"content-type\" content=\"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8\" >";


        var template = {
            head: "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:x=\"urn:schemas-microsoft-com:office:excel\" xmlns=\"http://www.w3.org/TR/REC-html40\" ><head>" + utf8Heading + "<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets>",
            sheet: {
                head: "<x:ExcelWorksheet><x:Name>",
                tail: "</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet>"
            },
            mid: "</x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body>",
            table: {
                head: "<table>",
                tail: "</table>"
            },
            foot: "</body></html>"
        };

        var tableRows = [];
        var keysBorder = [];

        // Styling variables
        var additionalStyles = "";
        var compStyle = null;

        // get contents of table except for exclude
        $(element).each( function(i,o) {
            var tempRows = "";

            $(o).find("table").not(settings.exclude).each(function (i,t) {
                var tableStyle = getComputedStyle(t);
                var borderTable = t.border;

                $(t).find("tr").not(settings.exclude).each(function (i,p) {

                    // Reset for this row
                    additionalStyles = "";

                    // Preserve background and text colors on the row
                    //Se agrega font-weight: bold;
                    if(settings.preserveColors){
                        compStyle = getComputedStyle(p);
                        additionalStyles += (compStyle && compStyle.backgroundColor ? "background-color: " + compStyle.backgroundColor + ";" : "");
                        additionalStyles += (compStyle && compStyle.color ? "color: " + compStyle.color + ";" : "");
                        additionalStyles += (compStyle && compStyle.fontWeight ? "font-weight: " + compStyle.fontWeight + ";" : "");
                    }

                    // Create HTML for Row
                    tempRows += "<tr style='" + additionalStyles + "'>";

                    // Loop through each TH and TD
                    $(p).find("td,th").not(settings.exclude).each(function (i,q) { // p did not exist, I corrected

                        // Reset for this column
                        additionalStyles = "";

                        // Preserve background and text colors on the row
                        if(settings.preserveColors){
                            compStyle = getComputedStyle(q);
                            additionalStyles += (compStyle && compStyle.backgroundColor ? "background-color: " + compStyle.backgroundColor + ";" : "");
                            additionalStyles += (compStyle && compStyle.color ? "color: " + compStyle.color + ";" : "");
                            additionalStyles += (compStyle && compStyle.textAlign ? "text-align: " + compStyle.textAlign + ";" : "");
                            additionalStyles += (compStyle && compStyle.fontSize ? "font-size: " + compStyle.fontSize + ";" : "");
                            additionalStyles += (compStyle && compStyle.fontWeight ? "font-weight: " + compStyle.fontWeight + ";" : "");
                            //additionalStyles += (compStyle && compStyle.border ? "border: " + compStyle.border + ";" : "");
                            additionalStyles += (compStyle && borderTable ? "border: thin solid black;" : "");

                            //additionalStyles += (compStyle && compStyle.width ? "width: " + compStyle.width + ";" : "");
                        }

                        var rc = {
                            rows: $(this).attr("rowspan"),
                            cols: $(this).attr("colspan"),
                            flag: $(q).find(settings.exclude)
                        };

                        if( rc.flag.length > 0 ) {
                            tempRows += "<td> </td>"; // exclude it!!
                        } else {
                            tempRows += "<td";
                            if( rc.rows > 0) {
                                tempRows += " rowspan='" + rc.rows + "' ";
                            }
                            if( rc.cols > 0) {
                                tempRows += " colspan='" + rc.cols + "' ";
                            }
                            if(additionalStyles){
                                tempRows += " style='" + additionalStyles + "'";
                            }
                            tempRows += " >" + $(q).html() + "</td>";
                        }
                    });

                    tempRows += "</tr>";

                });
            });

            tableRows.push(tempRows);
        });

        if(options.download == 1)
            this.tableToExcel(tableRows, settings, template, keysBorder);
        else
            return this.tableToExcel(tableRows, settings, template, keysBorder);
    },
    format: function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
    },
    tableToExcel: function (table, settings, template, keysBorder) {
        var name = settings.name;
        var sheetName = settings.sheetName;
        var filename = settings.filename;
        var fileext = settings.fileext;
        var fullTemplate="", i, link, a;

        sheetName = typeof sheetName === "undefined" ? "Sheet" : sheetName;

        var ctx = {
            worksheet: name || "Worksheet",
            table: table,
            sheetName: sheetName
        };

        fullTemplate= template.head;

        if ( $.isArray(table) ) {
                Object.keys(table).forEach(function(i){
                    //fullTemplate += e.template.sheet.head + "{worksheet" + i + "}" + e.template.sheet.tail;
                    fullTemplate += template.sheet.head + sheetName + i + template.sheet.tail;
            });
        }

        fullTemplate += template.mid;

        if ( $.isArray(table) ) {
                Object.keys(table).forEach(function(i){
                fullTemplate += template.table.head + "{table" + i + "}" + template.table.tail;
            });
        }

        fullTemplate += template.foot;

        for (i in table) {
            ctx["table" + i] = table[i];
        }
        delete ctx.table;

        var isIE = navigator.appVersion.indexOf("MSIE 10") !== -1 || (navigator.userAgent.indexOf("Trident") !== -1 && navigator.userAgent.indexOf("rv:11") !== -1); // this works with IE10 and IE11 both :)
        //if (typeof msie !== "undefined" && msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./))      // this works ONLY with IE 11!!!
        if (isIE) {
            if (typeof Blob !== "undefined") {
                //use blobs if we can
                fullTemplate = this.format(fullTemplate, ctx); // with this, works with IE
                fullTemplate = [fullTemplate];
                //convert to array
                var blob1 = new Blob(fullTemplate, { type: "text/html" });
                window.navigator.msSaveBlob(blob1, filename );
            } else {
                //otherwise use the iframe and save
                //requires a blank iframe on page called txtArea1
                txtArea1.document.open("text/html", "replace");
                txtArea1.document.write(this.format(fullTemplate, ctx));
                txtArea1.document.close();
                txtArea1.focus();
                sa = txtArea1.document.execCommand("SaveAs", true, filename );
            }

        } else {
            var blob = new Blob([this.format(fullTemplate, ctx)], {type: "application/vnd.ms-excel"});
            //var blob = new Blob([data], {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

            if(settings.download == 0){
                return blob;
            } else {
                window.URL = window.URL || window.webkitURL;
                link = window.URL.createObjectURL(blob);
                a = document.createElement("a");
                a.download = filename+fileext;
                a.href = link;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

        }

        return true;
    },



    beforeUpload(file) {
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (isLt2M) {
            return true;
        }
        this.$swal({
            type: "warning",
            title: "¡Operación no Permitida!",
            text: "El archivo no debe ser mayor a 2MB.",
            footer: ""
        });
        return false;
    },
    validaCodigo: function(val){
        var codigoValidado = val;
            var filter = this.model_vista.articulos.filter(function(e){
                for(var k in e.codigo_articulo){
                    if(e.codigo_articulo[k].toString().toLowerCase().trim()==val.toString().toLowerCase().trim()){
                        codigoValidado = e.codigo_articulo[k];
                        return true;
                    } else if (parseInt(e.codigo_articulo[k]) == parseInt(val)){
                        codigoValidado = e.codigo_articulo[k];
                        return true;
                    }
                }
            });
            if(filter.length>0)
                return codigoValidado.toString();
            else
                return "";
        },

    validaCargaLayout:async function (results) {
        var self = this;
        return new Promise(async function(resolve, reject) {
            var errores = 0;
            var registros = 0;
            var erroresText = '';
            var precios = {};
            var errorTmp = '';
            let usuario = self.$local_storage.get("sb_usuario");
            const patternFloat = /^[\d]*(\.{0,1}[\d]*)$/;
            const patternEntero = /^[\d]*$/;
            try {

                if (results.length == 0) {
                    errores++;
                    erroresText += "No hay datos en el excel. Verifique que exista información a partir de la sexta fila.";
                } else if (results.length > 500){
                    errores++;
                    erroresText += "El máximo de filas a procesar es 500, favor de verificar.";
                } else {
                    for (var k in results) {

                        if (results[k]['CÓDIGO BARRAS']!=null && results[k]['$ NUEVO']!=null
                            && results[k]['CÓDIGO BARRAS'].toString().trim()!="" && results[k]['$ NUEVO'].toString().trim()!= "") {

                            registros++;

                            var precioNuevo = results[k]['$ NUEVO'].toString().replace("$","").replace("$","").trim();
                            if (!patternFloat.test(precioNuevo) || precioNuevo=="") {
                                errores++;
                                errorTmp = '<br/>' + "- El precio " + results[k]['$ NUEVO'] + " no es una cantidad válida.";
                                if (erroresText.indexOf(errorTmp) == -1)
                                    erroresText += errorTmp;
                            } else
                                results[k]['$ NUEVO'] = precioNuevo;


                            var valCodigo =  self.validaCodigo(results[k]['CÓDIGO BARRAS']);
                            //Valida qué exista el codigo_barras en los articulos de la subasta
                            if( valCodigo == ""){
                                errores++;
                                errorTmp = '<br/>' + "- El código de barras " + results[k]['CÓDIGO BARRAS'] + " no se encontró en los articulos de la subasta.";
                                if (erroresText.indexOf(errorTmp) == -1)
                                    erroresText += errorTmp;
                            } else {
                                results[k]['CÓDIGO BARRAS'] = valCodigo;
                                //Comparando la cantidad por pedir, es como se puede identificar si es para abastos o no, y actualziar el precio (PROBLEMA: si piden la misma cantidad para abastos y matriz)
                                var key_articulo = valCodigo+"-"+results[k]['PEDIDO'].toString();

                                if(precios[key_articulo] != null){//Si ya estaba en la colección (Significa que puede haber un mismo artículo uno para matriz y otro para abastos)
                                    //Como se repite, entonces le ponemos otro key
                                    var key_duplicado = key_articulo.toString()+"-duplicado";
                                    precios[key_duplicado] = {};
                                    precios[key_duplicado].precio_proveedor = parseFloat(results[k]['$ NUEVO']).toFixed(4);
                                    precios[key_duplicado].agregado = 0;
                                    if(results[k]['OBS']!=null && results[k]['OBS'].toString().trim()!="")
                                        precios[key_duplicado].observaciones_proveedor = results[k]['OBS'].toString().trim();
                                    else
                                        precios[key_duplicado].observaciones_proveedor = "";
                                } else {
                                    precios[key_articulo] = {};
                                    precios[key_articulo].precio_proveedor = parseFloat(results[k]['$ NUEVO']).toFixed(4);
                                    precios[key_articulo].agregado = 0;
                                    if(results[k]['OBS']!=null && results[k]['OBS'].toString().trim()!="")
                                        precios[key_articulo].observaciones_proveedor = results[k]['OBS'].toString().trim();
                                    else
                                        precios[key_articulo].observaciones_proveedor = "";
                                }
                            }


                        }

                    }

                }

                if(registros == 0){
                    errores++;
                    erroresText += "No hay datos en el excel. Verifique que exista información a partir de la sexta fila.";
                }

                return resolve({
                    "errores": errores,
                    "erroresText": erroresText,
                    "precios":precios,
                });

            } catch (error) {
                console.log(error);
                return reject(error);
            }
        }); //promise
    },
    handleSuccess: async function ({
            results,
            header
        }) {
            var errores = 0;
            var erroresText = '';
            var importData = {};
            try {
                window.dialogLoader.show('Espere un momento por favor..');
                var self = this;

                        self.validaCargaLayout(results).then(validaciones => {
                            window.dialogLoader.show('Espere un momento por favor..');

                            if (validaciones.errores > 0) {
                                this.$swal({
                                    type: "warning",
                                    title: "¡Operación no Permitida!",
                                    text: "Existen errores al cargar el archivo. Corrija y vuelva a intentarlo.",
                                    footer: validaciones.erroresText,
                                });
                                window.dialogLoader.hide();
                            } else {
                                console.log("precios: ", validaciones.precios);
                                for(var articulok in self.model_vista.articulos){
                                    for(var codigok in self.model_vista.articulos[articulok].codigo_articulo){
                                        var codice = self.model_vista.articulos[articulok].codigo_articulo[codigok].toString();
                                        //Quitamos al proveedor de todos los articulos donde esté, esto por si vuelven a cargar el excel, se carguen solo lo que viene en el ultimo excel
                                        var encontrado = self.model_vista.articulos[articulok].proveedores.filter( e => {
                                            return e.id_proveedor != self.excel_proveedor.id_proveedor;
                                        });
                                        self.model_vista.articulos[articulok].proveedores = encontrado;

                                        //Comparando la cantidad por pedir, es como se puede identificar si es para abastos o no, y actualziar el precio (PROBLEMA: si piden la misma cantidad para abastos y matriz)
                                        var key_articulo = codice.toString()+"-"+self.model_vista.articulos[articulok].cantidad.toString();
                                        if(validaciones.precios[key_articulo] != null){
                                            //Agregar proveedor al articulo
                                            if(validaciones.precios[key_articulo].agregado == 1){//Si ya fue agregado, buscamos el duplicado
                                                if(validaciones.precios[key_articulo+"-duplicado"] != null && validaciones.precios[key_articulo+"-duplicado"].agregado == 0){
                                                    var prove = {
                                                        id_proveedor: self.excel_proveedor.id_proveedor,
                                                        nombre_proveedor: self.excel_proveedor.nombre_proveedor,
                                                        precio_proveedor: validaciones.precios[key_articulo+"-duplicado"].precio_proveedor,
                                                        observaciones_proveedor: validaciones.precios[key_articulo+"-duplicado"].observaciones_proveedor,
                                                        elegido: 0,
                                                        menor_precio: 0
                                                    };
                                                    self.model_vista.articulos[articulok].proveedores.push(prove);
                                                    validaciones.precios[key_articulo+"-duplicado"].agregado = 1;//Para identificar si este artículo ya fue usado para actualziar un precio de la subasta
                                                } else {
                                                    var prove = {
                                                        id_proveedor: self.excel_proveedor.id_proveedor,
                                                        nombre_proveedor: self.excel_proveedor.nombre_proveedor,
                                                        precio_proveedor: validaciones.precios[key_articulo].precio_proveedor,
                                                        observaciones_proveedor: validaciones.precios[key_articulo].observaciones_proveedor,
                                                        elegido: 0,
                                                        menor_precio: 0
                                                    };
                                                    self.model_vista.articulos[articulok].proveedores.push(prove);
                                                    validaciones.precios[key_articulo].agregado = 1;//Para identificar si este artículo ya fue usado para actualziar un precio de la subasta
                                                }                                                
                                            } else {
                                                var prove = {
                                                    id_proveedor: self.excel_proveedor.id_proveedor,
                                                    nombre_proveedor: self.excel_proveedor.nombre_proveedor,
                                                    precio_proveedor: validaciones.precios[key_articulo].precio_proveedor,
                                                    observaciones_proveedor: validaciones.precios[key_articulo].observaciones_proveedor,
                                                    elegido: 0,
                                                    menor_precio: 0
                                                };
                                                self.model_vista.articulos[articulok].proveedores.push(prove);
                                                validaciones.precios[key_articulo].agregado = 1;//Para identificar si este artículo ya fue usado para actualziar un precio de la subasta
                                            }                                            

                                        }
                                    }

                                }

                                if(["Subasta Abierta","En Proceso de Cotización"].includes(self.model_vista.estatus)){
                                    self.model_vista.estatus = "Cotización Recibida";
                                }

                                window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
                                .then(response => {
                                    window.dialogLoader.showSnackbar('Precios agregados correctamente', {
                                        color: 'success'
                                    });
                                    self.model_vista._rev = response.data.rev;
                                    self.modalVistaPreviaProveedores = false;
                                    self.modalCargar = false;
                                    self.registros.items = [];
                                    self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                                })
                                .catch(error => {
                                    self.modalVistaPrevia = false;
                                    self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al editar el artículo. Intente nuevamente.",
                                            footer: ""
                                        });
                                }).then(() => {
                                    window.dialogLoader.hide();
                                });

                            }
                        }).catch( error => {
                            self.$swal({
                                type: "warning",
                                title: "¡Operación no Permitida!",
                                text: "Error al cargar el archivo. Intente nuevamente.",
                                footer: error,
                            });
                            console.log(error);
                            window.dialogLoader.hide();

                        });


            } catch (error) {
                this.$swal({
                    type: "warning",
                    title: "¡Operación no Permitida!",
                    text: "Error al cargar el archivo. Verifique que no existan celdas vacías.",
                    footer: error.message,
                });
                window.dialogLoader.hide();
            }

        },

        //Revisar si todos los articulos tienen elegido un proveedor
        validaProveedorPendiente: function(){
            for(var k in this.model_vista.articulos){
                var elegido = this.model_vista.articulos[k].proveedores.reduce(function(total, prove){//Sabes si hay proveedor elegido
                    return total + parseFloat(prove.elegido);
                }, 0)
                if(elegido == 0 ||this.model_vista.articulos[k].precio_nuevo == null || this.model_vista.articulos[k].precio_nuevo == "")
                    return true;
            }
            return false;
        },

        generarOrdenesCompra: function(){
            if(this.model_vista.proveedores.length == 0){
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "No se han agregado Proveedores a la subasta. Favor de verificar.",
                    footer: "",                    
                });
            } else if(this.validaProveedorPendiente() == true){
                this.$swal({
                    type: "info",
                    title: "Cuidado",
                    text: "Hay artículos sin proveedor elegido. Esos artículos no se enviarán a la orden de compra. ¿Desea Continuar?",
                    footer: "",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Si",
                    cancelButtonText: "No"
                }).then(resultado => {
                    if (resultado.value) {
                        this.generarOrdenes();
                    }
                });
            } else {
                this.generarOrdenes();
            }
        },

        getDatosProveedor: function(id_proveedor){
            var data = {};
            for(var k in this.model_vista.proveedores){
                if(this.model_vista.proveedores[k].id_proveedor == id_proveedor){
                    var pro = this.model_vista.proveedores[k];
                    data = {
                        id_proveedor:pro.id_proveedor,
                        nombre_proveedor:pro.nombre_proveedor,
                        plazo: pro.plazo,
                        contacto: pro.contacto,
                        email: pro.email,
                        condiciones: pro.condiciones,
                        telefono_1: pro.telefono_1};
                    break;
                }
            }
            return data;
        },

        generarOrdenes: async function(){
            var self = this;
            this.$swal({
                type: "info",
                title: "Finalizar",
                text: "Se generará una orden de compra por cada proveedor con sus articulos. ¿Desea Continuar?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(async function(resultado){
                if (resultado.value) {

                    window.dialogLoader.show('Espere un momento por favor..');

                    var articulos_proveedor = {};


                    for(var k in self.model_vista.articulos){
                        var articulo = self.model_vista.articulos[k];
                        for(var kp in articulo.proveedores){
                            var prov = articulo.proveedores[kp];
                            if(prov.elegido == 1){
                                if(articulos_proveedor[prov.id_proveedor] == null){
                                    articulos_proveedor[prov.id_proveedor] = {"matriz":[], "abastos":[]};
                                }
                                var art = {
                                    id_articulo: articulo.id_articulo,
                                    nombre_articulo: articulo.nombre_articulo,
                                    codigo_articulo: articulo.codigo_articulo,
                                    descripcion: articulo.descripcion,
                                    impuestos_articulo: articulo.impuestos_articulo, 
                                    cantidad: articulo.cantidad,
                                    precio_compra: articulo.precio_compra,  //Último precio de compra
                                    precio_compra_con_iva: articulo.precio_compra_con_iva,  //Último precio de compra
                                    precio_sin_iva: (prov.precio_proveedor / (1 + (self.getSumaImpuestos(articulo.impuestos_articulo)/100))).toFixed(4),
                                    iva: self.getSumaImpuestos(articulo.impuestos_articulo), //Ahora el iva guarda la suma de impuestos del articulo                                    
                                    precio_con_iva: prov.precio_proveedor,
                                    porcentaje_descuento1: 0,
                                    porcentaje_descuento2: 0,
                                    precio_proveedor: prov.precio_proveedor,
                                    precio_proveedor_sin_iva: (prov.precio_proveedor / (1 + (self.getSumaImpuestos(articulo.impuestos_articulo)/100))).toFixed(4),
                                    existencia_articulo: articulo.existencia,
                                    observaciones_proveedor: prov.observaciones_proveedor,
                                    estatus:"Pendiente",
                                    cantidad_recibida: 0,
                                    cantidad_faltante: 0,
                                    cantidad_devuelta: 0,
                                    lotes_destino:[],
                                    resaltado: articulo.resaltado,
                                    sucursal_recibio:"",
                                    usuario_recibio:"",
                                    fecha_recibio:"",
                                };
                                if(articulo.resaltado == 1){ //1-abastos, 0-matriz
                                    articulos_proveedor[prov.id_proveedor]["abastos"].push(art);
                                } else {
                                    articulos_proveedor[prov.id_proveedor]["matriz"].push(art);
                                }
                                
                                break;
                            }

                        }
                    }

                    
                    for(var key_proveedor in articulos_proveedor){
                        var proveedor = self.getDatosProveedor(key_proveedor);

                        //MATRIZ                        
                            //console.log("key", key_proveedor);
                            //console.log("Articulos", articulos_proveedor[key_proveedor]);

                            let dataConsecutivo={
                                "id_sucursal": "", //requerido
                                "tabla":"compras", //requerido
                                "longitud":5  //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                                };
                            await window.funciones.getConsecutivo(dataConsecutivo)
                            .then(async function(resultConsecutivo) {

                                var consecutivo = resultConsecutivo;
                                var anio = window.moment().format("YYYY").toString().substr(-2);
                                var orden = "C"+ anio + consecutivo.toString();
                                
                                var model = {
                                    type:"pedidos",
                                    no_orden: orden,
                                    no_subasta: self.model_vista.no_subasta,
                                    id_sucursal_destino: self.suc_matriz._id,
                                    nombre_sucursal_destino: self.suc_matriz.nombre,
                                    tipo_sucursal: "matriz", 
                                    fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                    facturas:[],
                                    proveedor: proveedor,
                                    estatus: "Cotización Recibida",
                                    articulos: articulos_proveedor[key_proveedor]["matriz"],
                                    usuario: self.usuario,
                                    observaciones_orden:"",
                                    condiciones_orden:proveedor.condiciones,
                                    usuario_entrada:"",
                                    observaciones_entrada:"",
                                    fecha_finalizada:"",
                                };

                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model)
                                    .then(async response => {

                                        //(21-03-2023): Martin solicitó que se relacione los articulos con el proveedor, por en caso de que no estuviera relacionado
                                        await Promise.all(model.articulos.map(async (item) => {
                                            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + item.id_articulo;
                                            let data = {
                                                id_proveedor: model.proveedor.id_proveedor,
                                                nombre_proveedor: model.proveedor.nombre_proveedor,
                                            };

                                            window.axios
                                                .post(url, data)
                                                .then(response => {
                                                    if (response.data == true) {
                                                        //console.log("Articulo: "+item.nombre_articulo+" Agregado a proveedor correctamente");                                                
                                                    } else {
                                                        //console.log("Error Articulo: "+item.nombre_articulo+" no se agregó a proveedor");
                                                    }
                                                }).catch(error => {
                                                    console.log("Exception Articulo: "+item.nombre_articulo+": "+error);
                                                });
                                        }));
                                    }).catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al generar orden. Intente nuevamente.",
                                            footer: error
                                        });
                                    });

                            }).catch( err => {
                                console.log(err);
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                    footer: ""
                                });
                            });

                        

                        //ABASTOS                        
                            //console.log("key", key_proveedor);
                            //console.log("Articulos", articulos_proveedor[key_proveedor]);

                            let dataConsecutivo2={
                                "id_sucursal": "", //requerido
                                "tabla":"compras", //requerido
                                "longitud":5  //Opcional, default 5  longitud del numero, por ejemplo 00001, si es 3 es 001
                                };
                            await window.funciones.getConsecutivo(dataConsecutivo2)
                            .then(async function(resultConsecutivo2) {

                                var consecutivo2 = resultConsecutivo2;
                                var anio2 = window.moment().format("YYYY").toString().substr(-2);
                                var orden2 = "C"+ anio2 + consecutivo2.toString();

                                var model2 = {
                                    type:"pedidos",
                                    no_orden: orden2,
                                    no_subasta: self.model_vista.no_subasta,
                                    id_sucursal_destino: self.suc_abastos._id,
                                    nombre_sucursal_destino: self.suc_abastos.nombre,
                                    tipo_sucursal: "abastos", 
                                    fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                    facturas:[],
                                    proveedor: proveedor,
                                    estatus: "Cotización Recibida",
                                    articulos: articulos_proveedor[key_proveedor]["abastos"],
                                    usuario: self.usuario,
                                    observaciones_orden:"",
                                    condiciones_orden:proveedor.condiciones,
                                    usuario_entrada:"",
                                    observaciones_entrada:"",
                                    fecha_finalizada:"",
                                };

                                await window.axios
                                    .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', model2)
                                    .then(async response2 => {

                                        //(21-03-2023): Martin solicitó que se relacione los articulos con el proveedor, por en caso de que no estuviera relacionado
                                        await Promise.all(model2.articulos.map(async (item) => {
                                            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/_design/funciones_articulos/_update/agregar_proveedor/" + item.id_articulo;
                                            let data = {
                                                id_proveedor: model2.proveedor.id_proveedor,
                                                nombre_proveedor: model2.proveedor.nombre_proveedor,
                                            };

                                            window.axios
                                                .post(url, data)
                                                .then(response => {
                                                    if (response.data == true) {
                                                        //console.log("Articulo: "+item.nombre_articulo+" Agregado a proveedor correctamente");                                                
                                                    } else {
                                                        //console.log("Error Articulo: "+item.nombre_articulo+" no se agregó a proveedor");
                                                    }
                                                }).catch(error => {
                                                    console.log("Exception Articulo: "+item.nombre_articulo+": "+error);
                                                });
                                        }));
                                    }).catch(error => {
                                        self.$swal({
                                            type: "error",
                                            title: "¡Operación no Permitida!",
                                            text: "Ocurrió un error al generar orden. Intente nuevamente.",
                                            footer: error
                                        });
                                    });

                            }).catch( err => {
                                console.log(err);
                                self.$swal({
                                    type: "error",
                                    title: "¡Operación no Permitida!",
                                    text: "Ocurrió un error al obtener consecutivo. Intente nuevamente.",
                                    footer: ""
                                });
                            });

                        
                    }

                    self.modalArticulos = false;
                    self.model_vista.estatus = "Finalizada/Ordenes Creadas";
                    self.model_vista.fecha_finalizada = window.moment().format("YYYY-MM-DDTHH:mm:ss");

                    await window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + self.model_vista._id + '?conflicts=true', self.model_vista)
                        .then(response => {
                            self.model_vista._rev = response.data.rev;
                        })
                        .catch(error => {
                        }).then(() => {
                            window.dialogLoader.hide();
                            self.modalArticulos = false;
                            self.registros.items = [];
                            self.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
                        });

                }
            });

        },

    modalAdjuntar(row) {
            var self = this;
            self.file_adjunto = null;            
            self.model_adjuntos = [];            
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + row._id)
            .then(response => {
                row = response.data;
                self.model_vista = row;
                if (response.data._attachments != null)
                    for (var key in response.data._attachments) {
                        self.model_adjuntos.push({
                            "nombre": key,
                            "_id": response.data._id,
                            "_rev": response.data._rev
                        });
                    }                
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener el registro. Intente nuevamente.",
                    footer: ""
                });
                window.dialogLoader.hide();
            }).then(() => {
                window.dialogLoader.hide();
                this.modalAdjuntarDocumentos = true;
            });

        },
   

    adjuntarArchivo: function(){

        if (this.$refs.formadjuntos.validate()) {
            window.dialogLoader.show('Espere un momento por favor..');

            var name = encodeURIComponent(this.file_adjunto.name);
            var id_subasta = this.model_vista._id;
            var rev = this.model_vista._rev;

            var url = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+id_subasta+'/' + name;
            if(rev != ""){ //Si trae rev es que ya se habían adjuntado archivos antes
                url = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+id_subasta+'/' + name+"?rev="+rev;
            }

            var self = this;
            window.axios
            .put(url, self.file_adjunto, {headers:{'Content-Type':self.file_adjunto.type}})
            .then(response => {
                window.dialogLoader.showSnackbar('Archivo adjuntado correctamente', {
                    color: 'success'
                });
                self.modalAdjuntar(this.model_vista);
            })
            .catch(error => {
                window.dialogLoader.hide();
                console.log("ERROR ", error);
                self.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al adjuntar el archivo. Intente nuevamente.",
                    footer: ""
                });
            }).then(()=>{
                self.file_adjunto = null;
            });

        }

    },

    descargarAdjunto: function(row){
        var name = encodeURIComponent(row.nombre);

        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+row._id+'/'+name+"?rev="+row._rev;
        a.target = "_blank";
        a.click();
        a.parentNode.removeChild(a);
    },
    eliminarAdjunto: function(row){
        window.dialogLoader.show('Espere un momento por favor..');
        var name = encodeURIComponent(row.nombre);
        var self = this;

        window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/'+process.env.VUE_APP_COUCHDB_DATABASE+'/'+row._id+'/'+name+"?rev="+row._rev)
            .then(response => {
                window.dialogLoader.showSnackbar('Archivo eliminado correctamente', {
                    color: 'success'
                });
            })
            .catch(error => {
                self.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al eliminar el archivo. Intente nuevamente.",
                        footer: ""
                    });
            }).then(()=>{
                self.modalAdjuntar(self.model_vista);
            });
    },
    calcular: async function() {

        var self = this;
        this.loadingVentas = true;

        let plazo = parseFloat(this.plazo_calculo);

        //await this.get_existencia();

        let group_level = this.periodo == "1" ? "4" : this.periodo == "2" ? "3" : "2";

        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
        const [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
        const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
        let urlConditions = `&startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

        axios.get(url + urlConditions)
        .then(async response => {
            if (response.data.rows.length > 0) {
                let respuesta = response.data.rows;
                let procesado = [];

                //Aqui ya seria agrupar por dia, semana o mes
                if (this.periodo == "1") {
                    //console.log("busqueda por dias")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });
                } else if (this.periodo == "2") { //if si es por mes

                    //console.log("busqueda por mes")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });

                } else { // if si es por año
                    //console.log("busqueda por año");
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}`;

                        procesado.push({
                            "fecha": key,
                            "cantidad": x.value.cantidad,
                            "precio": x.value.precio_venta / x.value.cantidad,
                            "total": 0
                        });
                    });
                }

                //Obtener articulos equivalentes y obtener sus registros
                await this.get_ventas_articulo(this.id_articulo_calculo).then(function(equivalencias_procesado){
                    procesado = procesado.concat(equivalencias_procesado); 

                    var new_procesado = [];
                    //juntarlo por key
                    for(var k in procesado){
                        var fecha_key = new_procesado.find(e => e.fecha == procesado[k].fecha);
                        if(fecha_key){
                            fecha_key.cantidad += procesado[k].cantidad;
                            fecha_key.precio = (fecha_key.precio + procesado[k].precio)/ 2; //El que ya tenía, mas el de la misma fecha, entre 2, ya no entre cantidad
                        } else {
                            new_procesado.push(procesado[k]);
                        }
                    }

                    new_procesado.sort((a,b) => {
                        if (a.fecha < b.fecha) {
                            return -1;
                        }
                        if (a.fecha > b.fecha) {
                            return 1;
                        }
                        // si son iguales
                        return 0;
                    });

                    //llenar los datos de ventas
                    self.total_venta = 0;
                    self.promedio_venta = 0;
                    self.precio_promedio_venta = 0;

                    let sorted = []
                    new_procesado.forEach(x => {
                        sorted.push(x);
                        self.total_venta += x.cantidad;
                        self.promedio_venta += x.cantidad;
                        self.precio_promedio_venta += x.precio;
                    });

                    let numero_dias_tiempo = new Date(self.fecha_a_venta) - new Date(self.fecha_de_venta);
                    let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);
                    //console.log("DIAS: ", numero_dias);
                    if(numero_dias <= 0)
                        numero_dias = 1;
                    numero_dias += 1; //Se suma 1, por el día que se pierde en la resta

                    self.promedio_venta = self.promedio_venta > 0 ? parseFloat((self.promedio_venta / numero_dias).toFixed(2)) : 0;
                    self.precio_promedio_venta = self.precio_promedio_venta > 0 ? self.precio_promedio_venta / new_procesado.length : 0;

                    //Falta sacar los datos del articulo maximo, minimo, etc
                    if (sorted.length > 0) {
                        sorted.sort((a, b) => {
                        return a.cantidad - b.cantidad;
                        });

                        //CALCULO PILOS
                        /*self.consumo_minimo = self.periodo == "1" ? Math.ceil(sorted[0].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[0].cantidad / 30) : Math.ceil(sorted[0].cantidad / 365));
                        self.consumo_maximo = self.periodo == "1" ? Math.ceil(sorted[sorted.length - 1].cantidad) : (self.periodo == "2" ? Math.ceil(sorted[sorted.length - 1].cantidad / 30) : Math.ceil(sorted[sorted.length - 1].cantidad / 365));
                        self.consumo_medio = self.promedio_venta;

                        self.minimo = self.consumo_minimo * plazo;
                        self.reorden = (self.consumo_medio * plazo) + self.minimo;
                        self.maximo = (self.consumo_maximo * plazo) + self.minimo;

                        self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));*/

                        //CALCULO ENRIQUE
                        self.minimo = Math.ceil(self.promedio_venta * plazo);
                        self.reorden = Math.ceil((self.promedio_venta * plazo) + self.minimo);
                        self.maximo = Math.ceil((self.promedio_venta * (plazo+30)) + self.minimo); //Suponiendo que quieren surtir para 30 días 

                        if(parseFloat(self.existencia_articulo) >= self.maximo){
                            self.cantidad_pedir = 0;
                        } else {
                            self.cantidad_pedir = parseFloat((self.maximo - self.existencia_articulo).toFixed(2));
                        }

                    }
                    //console.log("Finalizando");

                    //Poner arreglo en reversa para mostrar la venta mas reciente al inicio
                    self.items_ventas = new_procesado.reverse();

                });
                                    
            } else {
                this.total_venta = 0;
                this.promedio_venta = 0;
                this.precio_promedio_venta = 0;
                this.minimo = 0;
                this.reorden = 0;
                this.maximo = 0;
                this.consumo_minimo = 0;
                this.consumo_medio = 0;
                this.consumo_maximo = 0;
                this.cantidad_pedir = 0;
                this.items_ventas = [];
            }
        })
        .catch(error => {
            console.log(error)
        }).then(()=>{               
            this.loadingVentas = false;
        });
    },

    get_ventas_articulo: function(id_articulo_padre, cantidad_padre = 1){
        var self = this;
        return new Promise(async function (resolve, reject) {
            let procesado = [];
            let group_level = self.periodo == "1" ? "4" : self.periodo == "2" ? "3" : "2";

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
            const [year_de, month_de, day_de] = self.fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = self.fecha_a_venta.split("-");

            var busca = encodeURI(escape(id_articulo_padre));
            
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/equivalencias?key=\"' + busca + '\"')
            .then(async responseEquivalencias => {
                if (responseEquivalencias.data != null && responseEquivalencias.data.rows != null && responseEquivalencias.data.rows.length > 0) {
                    //console.log("Buscando equivalencias artículo: ", id_articulo_padre);
                    for(var ke in responseEquivalencias.data.rows){
                        var equivalente = responseEquivalencias.data.rows[ke].value;
                        
                        var urlConditions = `&startkey=["${equivalente['id_articulo_equivalente']}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${equivalente['id_articulo_equivalente']}", "${year_a}", "${month_a}", "${day_a}"]`;

                        await axios.get(url + urlConditions)
                        .then(async response => {
                            if (response.data.rows.length > 0) {
                                console.log("Buscando ventas artículo: ", equivalente['id_articulo_equivalente']);
                                let respuesta = response.data.rows;                    

                                //Aqui ya seria agrupar por dia, semana o mes
                                if (self.periodo == "1") {
                                    //console.log("busqueda por dias")
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada, //Aqui no se divide pues el precio del equivalente es diferente al de la caja, se deja lo que se vendió como su precio
                                            "total": 0
                                        });
                                    });
                                } else if (self.periodo == "2") { //if si es por mes

                                    //console.log("busqueda por mes")
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}-${x.key[2]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada,
                                            "total": 0
                                        });
                                    });

                                } else { // if si es por año
                                    //console.log("busqueda por año");
                                    respuesta.forEach(x => {
                                        let key = `${x.key[1]}`;
                                        var cantidad_calculada = parseFloat((x.value.cantidad / parseFloat(equivalente["cantidad"]) / parseFloat(cantidad_padre)).toFixed(2));
                                        procesado.push({
                                            "fecha": key,
                                            "cantidad": cantidad_calculada,
                                            "precio": x.value.precio_venta / cantidad_calculada,
                                            "total": 0
                                        });
                                    });
                                }

                                //Buscamos si el equivalente tiene un subequivalente
                                await self.get_ventas_articulo(equivalente["id_articulo_equivalente"], equivalente["cantidad"]).then(function(equivalencias_procesado){
                                    procesado = procesado.concat(equivalencias_procesado); 
                                });                                    
                                
                            }
                        })
                        .catch(error => {
                            console.log(error);                            
                        });                        

                    }
                    return resolve(procesado);
                } else {
                    return resolve(procesado);
                }
            })
            .catch(error => {
                console.log("ERROR al buscar equivalencia2: ", error);   
                return resolve(procesado);  
            });
        });
        
    },
    consultar_compras: async function() {
        try {
            this.loadingCompras = true;
            this.items_compras = [];

            let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/compras/_view/compras_grupo';
            const [year_de, month_de, day_de] = this.fecha_de_venta.split("-");
            const [year_a, month_a, day_a] = this.fecha_a_venta.split("-");
            let urlConditions = `?startkey=["${this.id_articulo_calculo}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${this.id_articulo_calculo}", "${year_a}", "${month_a}", "${day_a}"]`;

            axios.get(url + urlConditions)
            .then(response => {
                if (response.data.rows.length > 0) {
                    let respuesta = response.data.rows;
                    let procesado = [];

                    //Aqui ya seria agrupar por dia, semana o mes
                    //console.log("busqueda por dias")
                    respuesta.forEach(x => {
                        let key = `${x.key[1]}-${x.key[2]}-${x.key[3]}`;

                        if(x.value.precio_proveedor.toString().trim() != ""){
                            procesado.push({
                                "fecha": key,
                                "cantidad": parseFloat(x.value.cantidad),
                                "existencia": x.value.existencia,
                                "precio": parseFloat(x.value.precio_proveedor),
                                "proveedor": x.value.proveedor.nombre_proveedor,
                                "orden": x.value.no_orden,
                                "total": 0
                            });
                        }
                    });
                    //console.log(procesado);

                    // Sacar los datos de la parte de abajo
                    this.total_compra = 0;
                    this.promedio_compra = 0;
                    this.precio_promedio_compra = 0;

                    procesado.forEach(x => {
                        this.total_compra += parseFloat(x.cantidad);
                        this.promedio_compra += parseFloat(x.cantidad);
                        this.precio_promedio_compra += parseFloat(x.precio);
                    });

                    let numero_dias_tiempo = new Date(this.fecha_a_venta) - new Date(this.fecha_de_venta);
                    let numero_dias = numero_dias_tiempo / (1000 * 3600 * 24);

                    this.promedio_compra = this.promedio_compra > 0 ? Math.ceil(this.promedio_compra / numero_dias) : 0;
                    this.precio_promedio_compra = this.precio_promedio_compra > 0 ? this.precio_promedio_compra / procesado.length : 0;

                    //Poner arreglo en reversa para mostrar la compra mas reciente al inicio
                    this.items_compras = procesado.reverse();
                } else {
                    this.total_compra = 0;
                    this.promedio_compra = 0;
                    this.precio_promedio_compra = 0;
                    this.items_compras = [];
                }
            })
            .catch(error => {
                this.items_compras = [];
                console.log(error)
            }).then(()=>{
                this.loadingCompras = false;
            });
        } catch (error) {
            console.log("Error al consultar compras: ", error);
        }
    },
    calcular_fecha: function(fecha) {
        this.fecha_de_venta = moment(String(fecha)).format("YYYY-MM-DD");;
        this.fecha_a_venta = moment().format("YYYY-MM-DD");

        this.calcular();
    },
    getNombreSucursal: function(id_sucursal) {
        var filter = this.sucursales.find(e => e._id == id_sucursal);
        if (filter)
            return filter.nombre;
        else
            return "";
    },
    get_sucursales: function () {
        window.dialogLoader.show('Espere un momento por favor..');
        let data = {
            "selector": {
                "nombre":{"$exists":true}
            },
            "fields": [
                "nombre", "_id","abastos","matriz"
            ],
            "use_index": "_design/84f098ad708a26e38d78e9daf0da60ca49fd02ea"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                this.sucursales_tipo = [];
                this.suc_abastos = "";
                this.suc_matriz = "";
                if (response.data.docs.length > 0){
                    this.sucursales = response.data.docs;
                    for(var k in this.sucursales){
                        if(this.sucursales[k].abastos == 1){
                            this.suc_abastos = this.sucursales[k];
                            this.sucursales_tipo.push(this.sucursales[k]);
                        }
                        if(this.sucursales[k].matriz == 1){
                            this.suc_matriz = this.sucursales[k];
                            this.sucursales_tipo.push(this.sucursales[k]);
                            this.sucursal_seleccionada = this.suc_matriz;
                        }
                    }
                }

                if(this.suc_abastos == ""){
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "No se ha configurado en Sucursales la sucursal Abastos. Es necesario para el módulo de subastas.",
                        footer: ""
                    }).then(()=>{
                        this.goHome();
                    });
                }
                
                if(this.suc_matriz == ""){
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "No se ha configurado en Sucursales la sucursal Matriz. Es necesario para el módulo de subastas.",
                        footer: ""
                    }).then(()=>{
                        this.goHome();
                    });
                }
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            }).then(()=>{
                window.dialogLoader.hide();
            });
    },    
    get_existencia: async function() {
        let n = 0;
        var self = this;
        await window.funciones_lotes.consultaExistencia({
            "id_articulo": this.id_articulo_calculo
        }).then(result => {
            if (result.length > 0) {

                result.forEach(el => {
                    n += el.existencia
                    el["sucursal"] = self.getNombreSucursal(el.id_sucursal);
                });
                this.articulo_existencias = result;
                this.existencia_articulo = n;
            } else {
                this.articulo_existencias = [];
            }
            //console.log("se asigno la existencia");

        }).catch(err => {
            console.log("error", err);
            this.existencia_articulo = "Error";
            this.articulo_existencias = [];
        });
    },
    consultarProveedor_combo: function () {
        if(this.id_proveedor != null && this.id_proveedor!=""){
            this.id_articulo = null;
            this.nombre_categoria = null;
            this.columnsArticulos = JSON.parse(JSON.stringify(this.columnsArticulosBakup));
            this.$refs.vuetablearticulos.resetQuery();
            window.dialogLoader.show('Espere un momento por favor..');
            var self = this;
            var proveedor = self.getProveedor(self.id_proveedor);

            self.limpiartablas();

            var busca = encodeURI(escape(self.id_proveedor));
            window.axios
            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/articulos_by_proveedor?key=\"'+busca+'\"')
            .then(async response => {

                if(response.data != null && response.data.rows!=null && response.data.rows.length>0){
                    var rows = response.data.rows;

                    var articulos = [];
                    //Obtener cantidad de ventas de  ultimo mes y de los ultimos 2 meses
                    let group_level = "1"; //Agrupamos por articulo, ya que solo queremos saber la cantidad de ventas
                    let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/ventas/_view/ventas_grupo?group_level=' + group_level;
                    //Ultimos 30 días
                    var fecha_de_venta = window.moment().subtract(1, 'months').format("YYYY-MM-DD");
                    var fecha_a_venta = window.moment().format("YYYY-MM-DD");
                    const [year_de, month_de, day_de] = fecha_de_venta.split("-");
                    const [year_a, month_a, day_a] = fecha_a_venta.split("-");
                    //Ultimos 60 días
                    var fecha_de_venta2 = window.moment().subtract(2, 'months').format("YYYY-MM-DD");
                    var fecha_a_venta2 = window.moment().format("YYYY-MM-DD");
                    const [year_de2, month_de2, day_de2] = fecha_de_venta2.split("-");
                    const [year_a2, month_a2, day_a2] = fecha_a_venta2.split("-");


                    //for (var k in rows) {
                    await Promise.all(rows.map(async (item) => {
                        var record = {
                            "articulo": item.value,
                            "proveedor": proveedor,
                        };
                        record.articulo["ventas_30_dias"] = 0;
                        record.articulo["ventas_60_dias"] = 0;
                        let urlConditions = `&startkey=["${record.articulo._id}", "${year_de}", "${month_de}", "${day_de}"]&endkey=["${record.articulo._id}", "${year_a}", "${month_a}", "${day_a}"]`;
                        let urlConditions2 = `&startkey=["${record.articulo._id}", "${year_de2}", "${month_de2}", "${day_de2}"]&endkey=["${record.articulo._id}", "${year_a2}", "${month_a2}", "${day_a2}"]`;
                        await axios.get(url + urlConditions)
                            .then(async response => {

                                if (response.data.rows.length > 0) {
                                    response.data.rows.forEach(x => {
                                        record.articulo["ventas_30_dias"] += x.value.cantidad;
                                    });
                                }

                                /*await axios.get(url + urlConditions2)
                                .then(async response => {

                                    if (response.data.rows.length > 0) {
                                        response.data.rows.forEach(x => {
                                            record.articulo["ventas_60_dias"] += x.value.cantidad;
                                        });
                                    }
                                })
                                .catch(error => {
                                    console.log(error);
                                });*/

                                //Contamos ventas de equivalentes
                                await this.get_conteo_ventas_articulo(record.articulo._id).then(function(conteo){
                                    record.articulo["ventas_30_dias"] += conteo["v30"];
                                    //record.articulo["ventas_60_dias"] += conteo["v60"];                                    
                                });                            
                            })
                            .catch(error => {
                                console.log(error);
                            }).then(async ()=>{
                                let _max = 0;
                                let _min = 0;
                                let _reorden = 0;
                                let _actual = 0;
                                let _actual_total = 0;
                                for (var j in record.articulo.stock) {
                                    _max += record.articulo.stock[j].maximo;
                                    _min += record.articulo.stock[j].minimo;
                                    _reorden += record.articulo.stock[j].reorden;
                                }

                                let datos_consulta = {
                                    "id_articulo": record.articulo._id,                                                                        
                                };
                                
                                await window.funciones_lotes.consultaExistencia(datos_consulta).then(result => {
                                    //console.log("Existencias: ", result);
                                    if (result.length > 0) {
                                        result.forEach(el => {
                                            if(el.id_sucursal == self.suc_matriz._id){
                                                _actual += el.existencia;//Martin solicitó que se muestre la existencia de matriz en la tabla    
                                            }
                                            _actual_total += el.existencia;
                                        });
                                    }
                                    //_actual += result;
                                    record.articulo['minimo'] = _min;
                                    record.articulo['reorden'] = _reorden;
                                    record.articulo['maximo'] = _max;
                                    record.articulo['actual'] = _actual;
                                    record.articulo['actual_total'] = _actual_total;                             
                                    
                                }).catch(err => {
                                    console.log("Ocurrió un error al consultar existencia de artículo.");

                                });
                            });
                        articulos.push(record);
                    }));

                    self.articulos_encontrados = articulos;
                    self.articulos_encontrados.sort(self.ordena_articulo);

                } else {
                    self.articulos_encontrados = [];
                    self.$swal({
                        type: "warning",
                        title: "Proveedor sin artículos!",
                        text: "El proveedor, no tiene artículos relacionados. Favor de verificar.",
                        footer: ""
                    });
                }
            })
            .catch(err => {
                console.log("Error en consultarProveedor: ", err);
            }).then(()=>{
                window.dialogLoader.hide();
            });
        }

    },

    getCategorias() {

        this.loadingCategorias = true;
        let data = {
            "selector": {
                "type": "categorias",
                "estatus": "Activo"
            },
            "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                response.data.docs.sort(this.ordenar_nombre);
                this.categorias = response.data.docs;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las categorias.",
                    footer: ""
                });
            }).then(() => {
                this.loadingCategorias = false;
            });
    },
    getDescripciones: function() {
        let data = {
            "selector": {
            "type": "descripciones",
            },
        };

        window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
        if (response.data.docs.length > 0) {
            response.data.docs.sort(this.ordenar_descripcion);
            this.descripciones = response.data.docs;                            
            this.optionsArticulos.listColumns["articulo.descripcion"] = this.descripciones.map(function(item) {
                    return {"id":item.descripcion,"text":item.descripcion}
                });
                this.optionsArticulosSugeridos.listColumns["articulo.descripcion"] = this.optionsArticulos.listColumns["articulo.descripcion"];
        } else{
            this.descripciones = [];                
        }
        })
        .catch(error => {
            this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "Ocurrió un error al obtener las descripciones.",
                footer: ""
            });
            console.log(error);
        });
    },
    ordenar_descripcion: function(a, b) {
        if (a.descripcion.toLowerCase() < b.descripcion.toLowerCase()) {
            return -1;
        }
        if (a.descripcion.toLowerCase() > b.descripcion.toLowerCase()) {
            return 1;
        }
        return 0;
    },
    ordenar_nombre: function( a, b ){
        if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
            return -1;
        }
        if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
            return 1;
        }
        return 0;
    },
    getArticulos:function(){
        this.loadingArticulos = true;
        let data = {
            "selector": {
                "type": "articulos",
                "estatus":"Activo",
                "$or":[
                    {"descripcion":{"$in":["CAJA","BULTO","FARDO"]}},
                    {"proveedores.0": { "$exists": true }}
                ]                
            },
            "fields":["_id","nombre","descripcion","codigo_barras","impuestos"],
            "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
        };
        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                response.data.docs.sort(this.ordenar_nombre);
                this.articulos = response.data.docs;
            })
            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener los articulos.",
                    footer: ""
                });
            }).then(() => {
                this.loadingArticulos = false;
            });
    },
    getArticulo: function(id_articulo){
        var filtro = this.articulos.find(function(e){
            return e._id == id_articulo;
        });
        if(filtro){
            return filtro;
        } else {
            return false;
        }
    },

  }
};
</script>
<style scoped>
.footer-hide>>>.VuePagination {
  display: none;
}
.compact {
    transform: scale(0.800);
    transform-origin: left;
    margin-top: 0px;
    margin-bottom: -10px;
    padding: 0px;
}
.bg-seleccionado {
    /*background-color: #861306 !important;*/
    background-color: #ABF6AF !important;
}

.bg-rojo {
    background-color: rgba(238, 92, 92, 0.813) !important;
}

.bg-resaltado {
    background-color: #FFF9C4 !important;
}

.oculto {
    display: none;
}
#orden_excel > table > tr > td{
    padding-left: 2px;
    padding-right: 2px;
}
#orden_excel > table > tr { page-break-inside: avoid }
.celda_titulo {
    font-size: 10px;
    text-align: center;
    color: red;
    border: 1px solid black !important;
}

.celda_datos {
    color:black;
    font-size: 11px;
    border: 1px solid black !important;
}

.celda_datos_resaltado {
    color:black;
    font-size: 11px;
    border: 1px solid black !important;
    background-color: #FFF9C4 !important;
}
.celda_vuetable_resaltado {
    background-color: #FFF9C4 !important;
}

.celda_datos_seleccionado {
    color:black;
    font-size: 11px;
    border: 1px solid black !important;
    /*background-color: #EF9A9A !important;*/
    background-color: #ABF6AF !important;
}

.v-dialog  #modalbusqueda >>> .table-bordered thead th, .table-bordered thead td {
  font-size: x-small !important;
  padding-left: 2px;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.v-dialog >>> .form-control {
  font-size: x-small !important;
}
.v-dialog fieldset legend{
  font-size: x-small !important;
}
.v-dialog #modalbusqueda >>> .v-input__control {
    font-size: 11px !important;
}
.v-dialog #modalbusqueda >>> .v-label {
    font-size: 11px !important;
}
.v-dialog #modal_articulos >>> .table-bordered thead th, .table-bordered thead td {
    font-size: x-small !important;
    padding-left: 2px;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.v-dialog #tablePrecios >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableBuscar >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableBuscarSugeridos >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #combos_busqueda {
    margin-top: 0px;
    margin-bottom: -5px;
}
.v-dialog #combos_busqueda >>> #row_combos {
    background-color: lightblue;
    font-weight: bolder;        
}
.v-dialog #combos_sugeridos {
    margin-top: 0px;
    margin-bottom: -8px;
}
.v-dialog #combos_sugeridos >>> #row_combos_sugeridos {
    background-color: lightblue;
    font-weight: bolder;    
}
.v-dialog #modalbusqueda >>> th {
    background-color: lightgoldenrodyellow;
    text-align: center !important;    
}
.v-dialog #tableExistencias >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableCompras >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableVentas >>> .col-md-12{
    margin-bottom: -25px;
}
.v-dialog #tableVentas >>> #filtroPeriodo .v-label, .error--text {
    font-size: 9px !important;
}

.v-dialog #modalbusqueda >>> .table td{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.v-dialog #modalbusqueda >>> .table th{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.v-dialog .tablafija thead {
  position: sticky;
  top: 0;
  background-color: #fff; /* color de fondo del encabezado fijo */
  z-index: 1; /* asegura que el encabezado fijo esté en la parte superior */
}
#datagrid td {
    /* overflow-x: hidden !important; 
    overflow-x: auto !important; */
    white-space: nowrap !important;    
    font-size: x-small !important;
    color: black !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
}
.dialog-content {
  overflow-x: auto; /* Habilita el desplazamiento horizontal */
  white-space: nowrap; /* Evita el salto de línea del contenido */
}
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
  color: black;
  font-size: x-small;
  background-color: #EEEEEE;
  padding: 0px;
  height: 20px;
}

/*div.v-dialog__content.v-dialog__content--active div.v-dialog.v-dialog--active div.v-card.v-sheet.theme--light div.v-card__text div#modalbusqueda.container.grid-list-md div.v-tabs.v-tabs--grow.theme--light div.v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text*/
.theme--light.v-tabs >>> .v-tabs-bar, .v-slide-group, .v-tabs-bar{
height: 20px;
margin-bottom: 0px;
}
.v-tabs .v-tabs-bar .v-tab.v-tab--active {
  color: white;
  font-size: x-small;
  background-color: #df7205;
  padding: 0px;
  font-weight: bold;
  height: 20px;
}
/*
.v-dialog .columnaProveedor, .columnaArticulo  {
  white-space: normal !important;
  min-width: 300px !important;
  max-width: 300px !important;  
}
.v-dialog .columnaCategoria {
  white-space: normal !important;
  min-width: 165px !important;
  max-width: 165px !important;  
}
.v-dialog .columnaUnidad {
  white-space: normal !important;
  min-width: 80px !important;
  max-width: 80px !important;  
}

.v-dialog .columnaPlazo, .columnaPrecioCompra, .columnaPrecioVenta{
  white-space: normal !important;
  min-width: 50px !important;
  max-width: 50px !important;  
}*/
</style>
